import React, { createContext, useContext, useState } from "react";

// const currentCourseData = {
//   courseData: "",
// };

// Define the initial state
const initialState = {
  courses: [],
};

// Create a context for the global state
const GlobalStateContext = createContext();

// Custom hook to access the global state
export const useGlobalState = () => useContext(GlobalStateContext);

// Global state provider component
export const GlobalStateProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  // Define helper functions to find course, module, lesson, and questions by id
  const findCourseById = (courseId) => {
    return state.courses.find((course) => course.courseId === courseId);
  };

  const findModuleById = (courseId, moduleId) => {
    const course = findCourseById(courseId);
    if (course) {
      return course.modules.find((module) => module.moduleId === moduleId);
    }
    return null;
  };

  const findLessonById = (courseId, moduleId, lessonId) => {
    const module = findModuleById(courseId, moduleId);
    if (module) {
      return module.lessons.find((lesson) => lesson.lessonId === lessonId);
    }
    return null;
  };

  const findQuestionsById = (courseId, moduleId, lessonId, questionId) => {
    const lesson = findLessonById(courseId, moduleId, lessonId);
    if (lesson) {
      return lesson.questions.find(
        (question) => question.questionId === questionId
      );
    }
    return null;
  };

  // GetAll functions
  const getAllCourses = () => {
    return state.courses;
  };

  // Function to get all modules by course ID
  const getAllModulesByCourseId = (courseId) => {
    const course = findCourseById(courseId);
    return course ? course.modules : [];
  };

  // Function to get all lessons by module ID
  const getAllLessonsByModuleId = (courseId, moduleId) => {
    const module = findModuleById(courseId, moduleId);
    return module ? module.lessons : [];
  };

  // Function to get all questions by lesson ID
  const getAllQuestionsByLessonId = (courseId, moduleId, lessonId) => {
    const lesson = findLessonById(courseId, moduleId, lessonId);
    return lesson ? lesson.questions : [];
  };

  // Function to add a new course

  const addCourse = (newCourse) => {
    console.log("this function is called");
    console.log("new value:", newCourse);
    setState((prevState) => ({
      ...prevState,
      courses: [...prevState.courses, newCourse],
    }));
  };

  // Function to add a new module to a course
  const addModuleToCourse = (courseId, newModule) => {
    console.log("Adding module to course:");
    console.log("Course ID:", courseId);
    console.log(newModule);

    setState((prevState) => ({
      ...prevState,
      courses: prevState.courses.map((course) =>
        course.courseId === courseId
          ? { ...course, modules: [...course.modules, newModule] }
          : course
      ),
    }));

    console.log("*********************");
    console.log("courses AFTER ADDING THE MODULE");
    console.log(state.courses);
  };

  // Function to add a new lesson to a module
  const addLessonToModule = (courseId, moduleId, newLesson) => {
    console.log("Adding lesson to module:");
    console.log("Course ID:", courseId);
    console.log("Module ID:", moduleId);
    console.log(newLesson);
    setState((prevState) => ({
      ...prevState,
      courses: prevState.courses.map((course) =>
        course.courseId === courseId
          ? {
              ...course,
              modules: course.modules.map((module) =>
                module.moduleId === moduleId
                  ? { ...module, lessons: [...module.lessons, newLesson] }
                  : module
              ),
            }
          : course
      ),
    }));
  };

  // // Function to add a new question to a lesson
  // const addQuestionToLesson = (courseId, moduleId, lessonId, newQuestion) => {
  //   console.log("######################")
  //   console.log("######################")
  //   console.log("addQuestionToLesson called")
  //   console.log("for course id:",courseId)
  //   console.log("for moduleId :",moduleId)
  //   console.log("for lessonId :",lessonId)
  //   console.log(newQuestion)
  //   console.log("######################")
  //   console.log("######################")
  //   setState((prevState) => ({
  //     ...prevState,
  //     courses: prevState.courses.map((course) =>
  //       course.courseId === courseId
  //         ? {
  //             ...course,
  //             modules: course.modules.map((module) =>
  //               module.moduleId === moduleId
  //                 ? {
  //                     ...module,
  //                     lessons: module.lessons.map((lesson) =>
  //                       lesson.lessonId === lessonId
  //                         ? {
  //                             ...lesson,
  //                             questions: [...lesson.questions, newQuestion],
  //                           }
  //                         : lesson
  //                     ),
  //                   }
  //                 : module
  //             ),
  //           }
  //         : course
  //     ),
  //   }));
  // };

  // // Function to add a new question to a lesson
  // const addQuestionToLesson = (courseId, moduleId, lessonId, newQuestion) => {
  //   console.log("######################");
  //   console.log("######################");
  //   console.log("addQuestionToLesson called");
  //   console.log("for course id:", courseId);
  //   console.log("for moduleId :", moduleId);
  //   console.log("for lessonId :", lessonId);
  //   console.log(newQuestion);
  //   console.log("######################");
  //   console.log("######################");
  //   setState((prevState) => {
  //     const updatedCourses = prevState.courses.map((course) => {
  //       if (course.courseId === courseId) {
  //         return {
  //           ...course,
  //           modules: course.modules.map((module) => {
  //             if (module.moduleId === moduleId) {
  //               return {
  //                 ...module,
  //                 lessons: module.lessons.map((lesson) => {
  //                   if (lesson.lessonId === lessonId) {
  //                     return {
  //                       ...lesson,
  //                       questions: [...lesson.questions, newQuestion],
  //                     };
  //                   }
  //                   return lesson;
  //                 }),
  //               };
  //             }
  //             return module;
  //           }),
  //         };
  //       }
  //       return course;
  //     });

  //     return {
  //       ...prevState,
  //       courses: updatedCourses,
  //     };
  //   });
  // };

  // Function to add a new question to a lesson
  const addQuestionToLesson = (courseId, moduleId, lessonId, newQuestion) => {
    console.log("Adding question to lesson:");
    console.log("Course ID:", courseId);
    console.log("Module ID:", moduleId);
    console.log("Lesson ID:", lessonId);
    console.log("New Question:", newQuestion);

    setState((prevState) => {
      const updatedCourses = prevState.courses.map((course) => {
        if (course.courseId === courseId) {
          return {
            ...course,
            modules: course.modules.map((module) => {
              if (module.moduleId === moduleId) {
                return {
                  ...module,
                  lessons: module.lessons.map((lesson) => {
                    if (lesson.lessonsId === lessonId) {
                      console.log("Lesson found, adding question...");
                      return {
                        ...lesson,
                        questions: [...lesson.questions, newQuestion],
                      };
                    }
                    return lesson;
                  }),
                };
              }
              return module;
            }),
          };
        }
        return course;
      });

      console.log("Updated Courses:", updatedCourses);

      return {
        ...prevState,
        courses: updatedCourses,
      };
    });
  };

  return (
    <GlobalStateContext.Provider
      value={{
        state,
        addCourse,
        addModuleToCourse,
        addLessonToModule,
        addQuestionToLesson,
        findCourseById,
        findModuleById,
        findLessonById,
        findQuestionsById,
        getAllModulesByCourseId,
        getAllLessonsByModuleId,
        getAllQuestionsByLessonId,
        getAllCourses,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
