import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import "../styling/training/CourseLandingPage.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CourseActionAssignComponent from "../components/training/CourseActionAssign";
import CourseActionEnrollComponent from "../components/training/CourseActionEnroll";
import CourseActionApprovalComponent from "../components/training/CourseActionApproval";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import axiosInstance from "../utils/axiosInstance";
import { getHeaders } from "../components/utils/useAuth";

const CourseLandingPage = ({
  setShowThumbnail,
  getCourseDetails,
  setStartCourse,
  setEnrollCourse,
  coursesList,
  setCoursesList,
  allCoursesList,
}) => {
  const [role, setRole] = useState("");
  const [permissons, setPermissions] = useState("");
  // Fetch user role and permissions
  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setRole(userRole);
    const userPermissions = localStorage.getItem("permissions");
    setPermissions(userPermissions);
    console.log("user permission", userPermissions);
  }, []);

  // State to manage courses data
  const [coursesData, setCoursesData] = useState([]);

  // State to manage modules data
  const [modulesData, setModulesData] = useState([]);

  // State to manage lessons data
  const [lessonsData, setLessonsData] = useState([]);

  // State to manage selected course ID
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  // State to manage selected module ID
  const [selectedModuleId, setSelectedModuleId] = useState(null);

  // State to manage expanded state of modules
  const [expandedModule, setExpandedModule] = useState([]);

  // Effect to fetch initial courses data
  useEffect(() => {
    axiosInstance
      .get(`/courses/stat`)
      .then((res) => {
        // const extendedData = [...res.data, ...res.data, ...res.data];
        console.log("course stat response");
        console.log(res.data);
        // setCoursesData(res.data);

        const userPermissions = localStorage.getItem("permissions");

        if (userPermissions === "hyper") {
          setCoursesData(res.data);
        } else {
          const approvedCourses = res.data.filter(
            (course) => course.status === "Approved"
          );

          setCoursesData(approvedCourses);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchCourseById = (courseID) => {
    axiosInstance
      .get(`/courses/` + courseID)
      .then((res) => {
        setSelectedCourse(res.data); // Update selectedCourse state with the fetched course data
        console.log("===============");
        console.log("course clicked");
        console.log(res.data);
        console.log("===============");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function to handle click on a course
  const handleCourseClick = (courseId) => {
    fetchCourseById(courseId); // Pass courseId to fetchCourseById function
    setSelectedCourseId(courseId);

    setSelectedModuleId(null); // Reset selected module ID
    setModulesData([]); // Clear modules data
    setLessonsData([]); // Clear lessons data
    setExpandedModule([]); // Clear expanded module state

    // Fetch modules data for the selected course
    axiosInstance
      .get(`/modules/` + courseId + `/all`)
      .then((res) => {
        console.log(res.data); // Log the response data
        setModulesData(res.data);
        // Initialize expanded state for modules
        const initialExpanded = res.data.map(() => false);
        setExpandedModule(initialExpanded);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function to handle click on a module
  const handleModuleClick = (moduleId) => {
    setSelectedModuleId(moduleId);
    setLessonsData([]); // Clear lessons data

    // Fetch lessons data for the selected module
    axiosInstance
      .get(`/lessons/` + moduleId + `/all`)
      .then((res) => {
        console.log("module clicked");
        console.log("lesson data fetched");
        console.log(res.data);
        setLessonsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function to toggle expansion of modules
  const toggleAccordion = (index) => {
    const newExpanded = [...expandedModule];
    newExpanded[index] = !newExpanded[index];
    setExpandedModule(newExpanded);
  };

  // State to manage selected course data
  const [selectedCourse, setSelectedCourse] = useState(
    coursesData.length > 0 ? coursesData[0] : null
  );

  // Tab value state
  const [value, setValue] = useState("1");

  // Function to handle tab change
  const handleChange = (e, tabValue) => {
    setValue(tabValue);
  };

  // Enrollment status
  // const enrollmentStatus = "in_progress";

  // Determine which tabs to show based on permissions
  // Enrollment status
  const getVisibleTabs = () => {
    switch (permissons) {
      case "normal":
        return ["1"];
      case "super":
        return ["1", "2"];
      case "hyper":
        return ["1", "2", "3"];
      default:
        return ["1"];
    }
  };
  const visibleTabs = getVisibleTabs();

  // Function to create a sanitized HTML element
  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <div>
      {/* <Navbar /> */}
      <div className="cl-bg">
        <div className="cl-content-section">
          {/* Left Section: Course List */}
          <div
            className="cl-left-section"
            style={{
              width: selectedCourseId === null ? "94.5vw" : "20vw",
            }}
          >
            <List sx={{ backgroundColor: "white" }}>
              {coursesData.map((course) => (
                <ListItem
                  key={course.course_id}
                  button
                  onClick={() => handleCourseClick(course.course_id)}
                  className="course-list-item"
                  selected={course.course_id === selectedCourseId}
                  sx={{
                    width: "18vw",
                    height: "5vw",
                    border: "1px solid",
                    borderColor:
                      course.course_id === selectedCourseId
                        ? "#1976d2"
                        : "#ddd",
                    borderRadius: "8px",
                    alignItems: "center",
                    transition: "background-color 0.3s ease",
                    marginBottom: "0.5vw",
                    backgroundColor:
                      course.courseID === selectedCourseId
                        ? "#e3f2fd"
                        : "white",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={course.course_banner}
                      alt={course.course_title}
                      className="course-banner"
                      sx={{
                        width: "5vw",
                        height: "3vw",
                        objectFit: "cover",
                        marginRight: "1vw",
                        borderRadius: "0.5vw",
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <span
                        dangerouslySetInnerHTML={createMarkup(
                          course.course_title
                        )} // Render HTML safely
                        className="course-list-title"
                        style={{
                          fontSize: "0.85vw",
                          fontWeight: "bold",
                          fontFamily: "'Roboto', sans-serif",
                        }}
                      />
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>

          {/* Mid Section: Course Details */}
          <div
            className="cl-mid-section"
            style={{
              display: selectedCourseId === null ? "none" : "block",
              width: selectedCourseId === null ? "0vw" : "32.5vw",
            }}
          >
            {selectedCourse && (
              <div>
                {/* Display selected course title and description */}
                <h3
                  dangerouslySetInnerHTML={{
                    __html: selectedCourse ? selectedCourse.title : "",
                  }}
                  // style={{ fontSize: "1.25vw" }}
                >
                  {/* {selectedCourse ? selectedCourse.title : ""} */}
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedCourse ? selectedCourse.description : "",
                  }}
                >
                  {/* {selectedCourse ? selectedCourse.description : ""} */}
                </p>

                {/* Accordion for Course Content */}
                <Accordion style={{ marginLeft: "1vw", marginRight: "1vw" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ backgroundColor: "#8E00FD" }}
                  >
                    <Typography style={{ color: "white" }}>
                      Course Content
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* List of Modules */}
                    {modulesData.map((module, moduleIndex) => (
                      <Accordion key={moduleIndex}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          style={{ backgroundColor: "#D9D9D9" }}
                          onClick={() => handleModuleClick(module.id)}
                        >
                          <Typography
                            dangerouslySetInnerHTML={{ __html: module.title }}
                          >
                            {/* {module.title} */}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {/* List of Lessons */}
                          <List>
                            {lessonsData.map((lesson, lessonIndex) => (
                              <ListItem key={lessonIndex}>
                                <ListItemText
                                  primary={
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: lesson.title,
                                      }}
                                    />
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </div>

          {/* Right Section: Course Actions */}
          {/* Right Section: Course Actions */}
          <div
            className="cl-right-section"
            style={{
              display: selectedCourseId === null ? "none" : "block",
              width: selectedCourseId === null ? "0vw" : "30vw",
            }}
          >
            {selectedCourse && (
              <Box
                sx={{ width: "100%", typography: "body1", textAlign: "center" }}
              >
                <img
                  src={selectedCourse ? selectedCourse.course_banner : ""}
                  alt="Course Banner"
                  style={{
                    width: "15vw",
                    maxWidth: "15vw",
                    marginTop: "0.5vw",
                    maxHeight: "8.5vw",
                    minHeight: "8.5vw",
                  }}
                />

                <TabContext
                  value={selectedCourse.status != "Approved" ? "3" : value}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "1vw",
                      width:
                        visibleTabs.length === 1
                          ? "30%"
                          : visibleTabs.length === 2
                          ? "60%"
                          : "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      centered
                    >
                      {visibleTabs.includes("1") && (
                        <Tab
                          label="Enroll"
                          value="1"
                          sx={{ fontSize: "0.75vw" }}
                          style={{
                            display:
                              selectedCourse.status === "Approved"
                                ? "block"
                                : "none",
                          }}
                        />
                      )}
                      {visibleTabs.includes("2") && (
                        <Tab
                          label="Assign"
                          value="2"
                          sx={{ fontSize: "0.75vw" }}
                          style={{
                            display:
                              selectedCourse.status === "Approved"
                                ? "block"
                                : "none",
                          }}
                        />
                      )}
                      {visibleTabs.includes("3") && (
                        <Tab
                          label="Approval"
                          value="3"
                          sx={{ fontSize: "0.75vw" }}
                        />
                      )}
                    </TabList>
                  </Box>

                  <TabPanel
                    value="1"
                    style={{
                      display:
                        selectedCourse.status === "Approved" ? "block" : "none",
                    }}
                  >
                    <CourseActionEnrollComponent
                      CourseID={selectedCourseId}
                      setShowThumbnail={setShowThumbnail}
                      getCourseDetails={getCourseDetails}
                      setStartCourse={setStartCourse}
                      setEnrollCourse={setEnrollCourse}
                    />
                  </TabPanel>
                  {visibleTabs.includes("2") && (
                    <TabPanel
                      value="2"
                      style={{
                        display:
                          selectedCourse.status === "Approved"
                            ? "block"
                            : "none",
                      }}
                    >
                      <CourseActionAssignComponent
                        CourseID={selectedCourseId}
                        coursesList={coursesList}
                        setCoursesList={setCoursesList}
                        allCoursesList={allCoursesList}
                      />
                    </TabPanel>
                  )}
                  {visibleTabs.includes("3") && (
                    <TabPanel value="3">
                      <CourseActionApprovalComponent
                        CourseID={selectedCourseId}
                        handleCourseSelect={handleCourseClick}
                      />
                    </TabPanel>
                  )}
                </TabContext>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLandingPage;
