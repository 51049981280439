import "../styling/Navbar.css";
import CPD_Logo from "../images/CPD_Logo.png";
import Search from "./Search";
import Profile from "./Profile";
import Notification from "./Notification";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="nav-bg">
      <Link to="/dashboard">
        <img className="nav-cpd-logo" src={CPD_Logo} />
      </Link>
      <div className="nav-right-section">
        {/* <Search/> */}
        {/* <Notification /> */}
        <Profile />
      </div>
    </div>
  );
}
