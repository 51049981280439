export const isValidPhoneNumber = (value) => {
  //   if (typeof value !== "string" || !value.trim()) {
  //     return false;
  //   }
  console.log("value recieved at this end", value);
  return !!value.match(/^[6789]\d{9}$/);
};

export const isValidEmail = (value) => {
  // Standard email validation regex
  const standardEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return standardEmailRegex.test(value);
};

export const isValidCompanyEmail = (value) => {
  // Company email validation
  const companyEmailRegex = /^[^\s@]+@copperpoddigital\.com$/;
  return companyEmailRegex.test(value);
};
