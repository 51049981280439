import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  InputBase,
  Button,
  Collapse,
  MenuItem,
  Select,
} from "@mui/material";
import axiosInstance from "../../utils/axiosInstance";
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import AdminNavbar from "../admin/common/AdNavbar";
import AdminLeftSideBar from "../admin/common/AdLeftSideBar";
import "../../styling/reports/CourseReport.css";
import { useNavigate } from "react-router-dom";

const SearchInput = ({ value, onChange, placeholder }) => (
  <InputBase
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    sx={{
      marginLeft: 1,
      flex: 1,
      border: "1px solid #ddd",
      padding: "0 10px",
      borderRadius: "4px",
      width: "50vw",
      height: "2.5vw",
      fontSize: "0.75vw",
    }}
  />
);

const CourseReport = () => {
  const [data, setData] = useState([]);
  const [approvedCourses, setApprovedCourses] = useState([]);
  const [showCourseReport, setShowCourseReport] = useState(true);
  const [showEmployeeReport, setShowEmployeeReport] = useState(false);
  const [courseSearch, setCourseSearch] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axiosInstance
      .get("/report/")
      .then((response) => {
        const processedData = response.data.map((item, index) => ({
          ...item,
          id: index,
          "Start Date": new Date(item["Start Date"]).toLocaleDateString(),
          "End Date": item["End Date"]
            ? new Date(item["End Date"]).toLocaleDateString()
            : "",
          "Last Login": item["Last Login"]
            ? new Date(item["Last Login"]).toLocaleDateString() +
              " " +
              new Date(item["Last Login"]).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            : "",
        }));
        setData(processedData);
      })
      .catch((error) => console.error("Error fetching data:", error));

    axiosInstance
      .get("/courses/stat")
      .then((response) => {
        const approvedCoursesList = response.data.filter(
          (course) => course.status === "Approved"
        );
        console.log("approved courses list", approvedCoursesList);
        setApprovedCourses(approvedCoursesList);
      })
      .catch((error) =>
        console.error("Error fetching approved courses:", error)
      );
  };

  const filteredCourseData = data.filter((item) => {
    const matchesCourse = item["Course Enrolled"]
      .toLowerCase()
      .includes(courseSearch.toLowerCase());
    return (
      (selectedCourse ? item["Course Enrolled"] === selectedCourse : true) &&
      matchesCourse
    );
  });

  const filteredEmployeeData = filteredCourseData.filter((item) => {
    const fullName = `${item["First Name"]} ${item["Last Name"]}`.toLowerCase();
    const email = item["Email"]?.toLowerCase() || "";
    const phone = (item["Phone"]?.toString() || "").toLowerCase(); // Convert phone number to string
    const searchQuery = employeeSearch.toLowerCase();

    return (
      (fullName.includes(searchQuery) ||
        email.includes(searchQuery) ||
        phone.includes(searchQuery)) &&
      (selectedEmployees.length > 0
        ? selectedEmployees.includes(fullName)
        : true)
    );
  });

  const handleCourseChange = (event) => setSelectedCourse(event.target.value);
  const handleCourseSearchChange = (event) =>
    setCourseSearch(event.target.value);
  const handleEmployeeSearchChange = (event) =>
    setEmployeeSearch(event.target.value);
  const handleEmployeeSelect = (event) =>
    setSelectedEmployees(event.target.value);

  const toggleReport = (report) => {
    if (report === "course") {
      setShowCourseReport(!showCourseReport);
      if (showEmployeeReport) setShowEmployeeReport(false);
    } else {
      setShowEmployeeReport(!showEmployeeReport);
      if (showCourseReport) setShowCourseReport(false);
    }
  };

  const exportData = (data, filename) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  return (
    <div className="course-report-bg">
      <AdminLeftSideBar />
      <div className="course-report-rgt-sec">
        <AdminNavbar />
        <Box padding={3}>
          <Box mb={4}>
            <Button
              variant="contained"
              onClick={() => toggleReport("course")}
              sx={{
                marginRight: 2,
                minHeight: "2.5vw",
                maxHeight: "2.5vw",
                minWidth: "10vw",
                maxWidth: "10vw",
                fontSize: "0.75vw",
              }}
            >
              Course Report
            </Button>
          </Box>

          <Collapse in={showCourseReport} timeout="auto" unmountOnExit>
            <Box mb={4}>
              {/* <Typography variant="h6">Course Report</Typography> */}
              <Box
                display="flex"
                alignItems="center"
                mb={2}
                className="search-select-container"
              >
                <SearchInput
                  placeholder="Search by First Name, Last Name, Phone or Employee Mail"
                  value={employeeSearch}
                  onChange={handleEmployeeSearchChange}
                />
                <Button
                  variant="contained"
                  onClick={() => fetchData()} // Refresh the data
                  sx={{
                    minHeight: "2.5vw",
                    maxHeight: "2.5vw",
                    minWidth: "8.25vw",
                    maxWidth: "8.25vw",
                    fontSize: "0.75vw",
                    marginLeft: "1vw",
                  }}
                >
                  Refresh
                </Button>

                <Select
                  value={selectedCourse}
                  onChange={handleCourseChange}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 300,
                        width: 250,
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                      },
                    },
                    MenuListProps: {
                      sx: {
                        padding: 0,
                        "& .MuiMenuItem-root": {
                          fontFamily: "Arial, sans-serif",
                          fontSize: "0.85vw",
                          fontWeight: "bold",
                        },
                      },
                    },
                  }}
                  sx={{
                    marginLeft: 2,
                    minHeight: "2.5vw",
                    maxHeight: "2.5vw",
                    minWidth: "10vw",
                    maxWidth: "10vw",
                    fontSize: "0.85vw",
                  }}
                >
                  <MenuItem value="">Select a Course</MenuItem>
                  {approvedCourses.map((course) => (
                    <MenuItem key={course.id} value={course.course_title}>
                      {course.course_title}
                    </MenuItem>
                  ))}
                </Select>

                <Button
                  variant="contained"
                  onClick={() =>
                    exportData(filteredEmployeeData, "EmployeeReport")
                  }
                  sx={{
                    marginLeft: 2,
                    marginRight: "1.5vw",
                    height: "2.5vw",
                    minWidth: "8.25vw",
                    maxWidth: "8.25vw",
                    fontSize: "0.75vw",
                  }}
                >
                  Export
                </Button>
              </Box>

              <div className="data-grid-container">
                <DataGrid
                  rows={filteredEmployeeData} // Use filtered data
                  columns={[
                    {
                      field: "Email",
                      headerName: "Official Mail ID",
                      width: 175,
                    },
                    {
                      field: "First Name",
                      headerName: "First Name",
                      width: 100,
                    },
                    { field: "Last Name", headerName: "Last Name", width: 100 },
                    { field: "Phone", headerName: "Phone", width: 100 },
                    {
                      field: "Course Enrolled",
                      headerName: "Course",
                      width: 200,
                    },
                    {
                      field: "Start Date",
                      headerName: "Start Date",
                      width: 100,
                    },
                    { field: "End Date", headerName: "End Date", width: 100 },
                    {
                      field: "Last Login",
                      headerName: "Last Login",
                      width: 150,
                    },
                    { field: "Status", headerName: "Status", width: 75 },
                  ]}
                  pageSize={5}
                  disableSelectionOnClick
                  autoHeight={false} /* Can control the height manually */
                  componentsProps={{
                    pagination: { labelRowsPerPage: "Rows per page:" },
                  }}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    fontFamily: "Arial, sans-serif",
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "bold",
                    },
                    "& .MuiDataGrid-cell": {
                      fontSize: "0.85vw",
                    },
                  }}
                />
              </div>
            </Box>
          </Collapse>

          <Collapse in={showEmployeeReport} timeout="auto" unmountOnExit>
            {/* Additional code for Employee Report */}
          </Collapse>
        </Box>
      </div>
    </div>
  );
};

export default CourseReport;
