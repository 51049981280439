import "../../styling/appraisal/apprsl.css";
import AdminNavbar from "../admin/common/AdNavbar";
import AdminLeftSideBar from "../admin/common/AdLeftSideBar";
import { useState, useRef, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import History from "./history";
import GeneralInformationForm from "./GenInfo";
import ResponsibilitiesForm from "./Responsibilities";
import PerformanceEvaluationForm from "./PerformanceEvaluation";
import CommentsGoalsForm from "./CommentsAndGoals";
import { useNavigate } from "react-router-dom";
import OverallPerformanceReview from "./OverallPerformanceReview";
import { useSelector, useDispatch } from "react-redux";
import { setIsDataUploaded } from './AppraisalReducer'
import { getItem } from "../../utils/storage";


const Apprsl = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  let steps = [
    "Home",
    "General Information",
    "Responsibilities",
    "Performance Evaluation",
    "Comments & Goals",
    "Preview",
  ]
  //const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [submitted, setSubmitted] = useState(false); // Track if the form is submitted
  const [showConfirmation, setShowConfirmation] = useState(false); // For showing the confirmation dialog
  const [selectedEmployee, setSelectedEmployee] = useState(null); // Track selected employee
  const [selectedApprslId, setSelectedApprslId] = useState(null); // Track selected apprsl id
  const [employeeId, setEmployeeId] = useState(null); // Track selected employee ID
  const [apprslId, setApprslId] = useState(null); // Track selected apprsl id
  const [confirmBack, setConfirmBack] = useState(false); // State to track if back confirmation is required
  const validationRef = useRef(null); // To store the validation function of each child component


  // useEffect(() => {
  //   if (getItem("role") == 'user') {
  //     steps = [
  //       "Home",
  //       "Comments & Goals",
  //       "Preview",
  //     ]
  //     // setSteps([
  //     //   "Home",
  //     //   "Comments & Goals",
  //     //   "Preview",
  //     // ]);
  //   } else {
  //     steps = [
  //       "Home",
  //       "General Information",
  //       "Responsibilities",
  //       "Performance Evaluation",
  //       "Comments & Goals",
  //       "Preview",
  //     ]
  //     // setSteps([
  //     //   "Home",
  //     //   "General Information",
  //     //   "Responsibilities",
  //     //   "Performance Evaluation",
  //     //   "Comments & Goals",
  //     //   "Preview",
  //     // ]);
  //   }
  // }, [])

  // Define the function for creating a new form
  const onCreateNewForm = (employee, apprsl, action) => {
    if (action == "Preview") {
      // added this extra argument action to see the preview of the page
      setApprslId(apprsl);
      setActiveStep(5);
      return;
    } else if (!employee) {
      alert("Please select an employee before creating a new form.");
      return;
    } else {
      setSelectedEmployee(employee);
      setEmployeeId(employee); // Store the selected employee ID
      setApprslId(apprsl); // Store the selected apprsl ID
      setActiveStep(1); // Move to the next step after selecting the employee and apprsl
    }
  };

  //Whenever user clicks on edit icon in preview page he will be redirected to particular page
  const onEditContent = (apprsl, page) => {
    setApprslId(apprsl); // Store the selected apprsl ID
    if (page == "generalInfo") {
      setActiveStep(1);
    }
    if (page == "Responsibilities") {
      setActiveStep(2);
    }
    if (page == "PerformanceEval") {
      setActiveStep(3);
    }
    if (page == "Comments") {
      setActiveStep(4);
    }
  };

  // Define the function for creating a new form
  const onAppraisalCreate = (employee, apprsl) => {
    if (!employee) {
      alert("Please select an employee before creating a new form.");
      return;
    }
    setSelectedEmployee(employee);
    setEmployeeId(employee); // Store the selected employee ID
    setSelectedApprslId(apprsl);
    setApprslId(apprsl); // Store the selected apprsl ID
  };

  // Handle next button click
  const handleNext = () => {
    if (activeStep === 0 && !selectedEmployee) {
      alert("Please select an employee before creating a new form.");
      return; // Stop if no employee is selected on the first step
    }

    if (validationRef.current && validationRef.current()) {
      if (activeStep === steps.length - 1) {
        // If it's the last step, simulate form submission
        // alert("Successfully submitted!");
        // setSubmitted(true); // Mark the form as submitted
        // setActiveStep(0); // Reset to the initial step (0 or 1 based on your preference)
        //dispatch(setIsDataUploaded(true))
        alert("Appraisal submitted successfully");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      alert("Please complete the current step before proceeding.");
    }
  };

  // Handle back button click
  const handleBack = () => {
    setConfirmBack(true); // Show confirmation before going back
  };

  // Handle user confirmation to navigate back
  const handleConfirmBack = () => {
    setShowConfirmation(false);
    setConfirmBack(false); // Reset back confirmation state
    setActiveStep((prevActiveStep) => prevActiveStep - 1); // Proceed to the previous step
  };

  // Handle cancellation of the confirmation dialog
  const handleCancelBack = () => {
    setShowConfirmation(false);
    setConfirmBack(false); // Reset back confirmation state
  };

  // Capture the selected employee from the History component
  const handleEmployeeSelect = (employee) => {
    setSelectedEmployee(employee); // Set the selected employee state
  };

  // redirect the page to home
  const handleHomeClick = () => {
    //setApprslId(null)
    setActiveStep(0);
  };

  // Render the form for the current step
  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <History
            validate={validationRef}
            onCreateNewForm={onCreateNewForm} // Pass the function to handle form creation
            onEmployeeSelect={handleEmployeeSelect} // Pass the employee selection handler
            employeeId={employeeId} // Pass employee ID
          />
        );
      case 1:
        return (
          <GeneralInformationForm
            validate={validationRef}
            onAppraisalCreate={onAppraisalCreate}
            employeeId={employeeId} // Pass employee ID
            apprslId={apprslId} // Pass apprsl ID
          />
        );
      case 2:
        return (
          <ResponsibilitiesForm
            validate={validationRef}
            employeeId={employeeId} // Pass employee ID
            apprslId={apprslId} // Pass apprsl ID
          />
        );
      case 3:
        return (
          <PerformanceEvaluationForm
            validate={validationRef}
            employeeId={employeeId} // Pass employee ID
            apprslId={apprslId} // Pass apprsl ID
          />
        );
      case 4:
        return (
          <CommentsGoalsForm
            validate={validationRef}
            employeeId={employeeId} // Pass employee ID
            apprslId={apprslId} // Pass apprsl ID
          />
        );
      case 5:
        return (
          <OverallPerformanceReview
            onEditContent={onEditContent}
            validate={validationRef}
            employeeId={employeeId} // Pass employee ID
            apprslId={apprslId} // Pass apprsl ID
          />
        );
      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <div className="apprsl-bg">
      <AdminLeftSideBar />
      <div className="apprsl-rgt-sec">
        <AdminNavbar />
        <div className="apprsl-stepper-container">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* Scrollable Content Box */}
          <div className="apprsl-content-box">
            {renderStepContent(activeStep)}
          </div>

          {/* Buttons for navigation */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "0.75vw",
              fontSize: "1vw",
            }}
          >
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleHomeClick}
                >
                  Home
                </Button>
              </div>
            ) : (
              <Button
                disabled={activeStep === 0}
                variant="contained"
                onClick={handleNext}
              >
                Save and continue
              </Button>
            )}
          </Box>

          {/* Confirmation Dialog */}
          <Dialog open={confirmBack} onClose={handleCancelBack}>
            <DialogTitle>{"Confirm Navigation"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to go to the previous step? Any unsaved
                changes may be lost.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelBack} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleConfirmBack} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Apprsl;
