import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../auth/Login";
import SignUp from "../auth/SignUp";
import Dashboard from "../pages/Dashboard";
import TestResults from "../pages/TestResults";
import Navbar from "../components/Navbar";
import UserProfile from "../pages/UserProfile";
import ChangePassword from "../auth/ChangePassword";
import ResetPassword from "../auth/ResetPassword";
import Homepage from "../pages/Homepage";
import MyCertificates from "../pages/MyCertificates";
import CoursesList from "../pages/CoursesList";
import ModulesList from "../pages/ModulesList";
import Lesson from "../pages/Lesson";
import ViewCertificate from "../pages/ViewCertificate";
import ModuleReults from "../pages/ModuleResults";
import UserManagement from "../pages/UserManagement";
import CourseCreation from "../pages/AdCourseCreation";
import CourseDetailsTab from "../pages/AdCourseDetailsTab";
import CourseManagement from "../pages/AdCourseManagement";
import CreateUserProfile from "../pages/AdCreateUserProfile";
import AdminDashboard from "../pages/AdDashboard";
import CourseLandingPage from "../pages/CourseLandingPage";
import TestComponent from "../components/test";
import useUserActivity from "../utils/userActivity";
import CourseReportChart from "../components/reports/CourseReport";
import OrganisationTree from "../pages/OrganisationTreeOuter";
import Appraisal from "../components/appraisal/apprsl";
import OverallPerformanceReview from "../components/appraisal/OverallPerformanceReview";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import { getItem } from "../utils/storage";

const Router = () => {
    
    const userType = getItem("role")
    return (
        <>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<ProtectedRoute />}>
                    <Route path="/homepage" element={<Homepage />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    {/* <Route path="/register" element={<SignUp />} /> */}
                    <Route path="/lesson/:moduleID" element={<Lesson />} />
                    <Route path="/testresult" element={<TestResults />} />
                    <Route path="/user-settings" element={<UserProfile />} />
                    {/* <Route path="/change-password" element={<ChangePassword />} /> */}
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/my-certificates" element={<MyCertificates />} />
                    <Route path="/my-courses" element={<CoursesList />} />
                    <Route path="/moduleLists/:courseID" element={<ModulesList />} />
                    <Route path="/view-certificate" element={<ViewCertificate />} />
                    <Route path="/module-results" element={<ModuleReults />} />
                    <Route path="/user-management" element={<UserManagement />} />
                    <Route path="/course-creation" element={<CourseCreation />} />
                    <Route path="/course-details" element={<CourseDetailsTab />} />
                    <Route path="/course-management" element={<CourseManagement />} />
                    <Route path="/create-profile" element={<CreateUserProfile />} />
                    <Route path="/admin-dashboard" element={<AdminDashboard />} />
                    <Route path="/course-landing" element={<CourseLandingPage />} />
                    <Route path="/test" element={<TestComponent />} />
                    <Route path="/course-report" element={<CourseReportChart />} />
                    {/* <Route path="/organisation-tree" element={<OrganisationTree />} /> */}
                    {/* {userType != "user" ?
                        <>
                            <Route path="/apprsl" element={<Appraisal />} />
                        </> :
                        <>
                            <Route path="/" element={<Navigate to="/Dashboard" />} />  
                            <Route path="/apprsl" element={<Navigate to="/" />} />
                        </>} */}
                    <Route path="/apprsl" element={<Appraisal />} />
                    <Route
                        path="/overallPerformancePage"
                        element={<OverallPerformanceReview />}
                    />
                </Route>
            </Routes>
        </>
    );
};

export default Router;
