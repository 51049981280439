import React from "react";
import "../styling/LeftSidebar.css";
import TrainingDevelopmentBtnIcon from "../images/Training&DevelopmentBtnIcon.png";
import CareerConvBtnIcon from "../images/CareerConvBtnIcon.png";
import HrPolicyDocBtnIcon from "../images/HrPolicyDocBtnIcon.png";
import HelpSupportBtnIcon from "../images/HelpSupportBtnIcon.png";
import EmpDirBtnIcon from "../images/EmpDirBtnIcon.png";
import SurveyBtnIcon from "../images/SurveyBtnIcon.png";
import SignOutBtnIcon from "../images/SignOutBtnIcon.png";

import Logo from "../images/CPD_Logo_Black.png";

function handleTrainDev() {
  // Training & Development
}

function handleCareerConv() {
  // Career Conversations
}

function handleHr() {
  // HR Policies & Documents
}

function handleHelp() {
  // Help & Support
}

function handleDirectory() {
  // Employee Directory
}

function handleSurveys() {
  // Surveys & Pulse Checks
}

export default function LeftSidebar() {
  const buttonData = [
    {
      id: 1,
      label: "Training & Development",
      imgsrc: TrainingDevelopmentBtnIcon,
      submithandler: handleTrainDev,
    },
    {
      id: 2,
      label: "Career Conversations",
      imgsrc: CareerConvBtnIcon,
      submithandler: handleCareerConv,
    },
    {
      id: 3,
      label: "HR Policies & Documents",
      imgsrc: HrPolicyDocBtnIcon,
      submithandler: handleHr,
    },
    {
      id: 4,
      label: "Help & Support",
      imgsrc: HelpSupportBtnIcon,
      submithandler: handleHelp,
    },
    {
      id: 5,
      label: "Employee Directory",
      imgsrc: EmpDirBtnIcon,
      submithandler: handleDirectory,
    },
    {
      id: 6,
      label: "Surveys & Pulse Checks",
      imgsrc: SurveyBtnIcon,
      submithandler: handleSurveys,
    },
  ];

  return (
    <div className="left-sidebar-bg">
      <div className="logo-container">
        <img src={Logo} alt="Logo" className="logo" />
      </div>
      {buttonData.map((button) => (
        <div key={button.id} className="button" onClick={button.submithandler}>
          <img src={button.imgsrc} alt={button.label} />
          <span>{button.label}</span>
        </div>
      ))}
      <div className="sign-out">
        <img src={SignOutBtnIcon} alt="Sign Out" />
        <span>Sign Out</span>
      </div>
    </div>
  );
}
