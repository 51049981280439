import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// export const getHeaders = () => ({
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
// });

export const getHeaders = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No auth token found");
  }
  return {
    Authorization: `Bearer ${token}`,
  };
};

export const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("useAuth hook called, token:", token);
    if (!token) {
      console.log("No token found, navigating to login");
      navigate("/login");
    }
  }, [navigate]);
};
