import React, { useState } from "react";
import ConfirmationPopup from "../utils/confirmationPopup";
import AlertMessage from "../utils/alertMessage";

const TestComponent = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false); // Track cancelling state

  const handleConfirm = () => {
    // Perform actions on confirmation
    setShowConfirmation(false);
    // Simulate API call or operation success/failure
    const isSuccess = true; // Replace with actual success check
    if (isSuccess) {
      setShowSuccess(true); // Show success message
    } else {
      setShowFailure(true); // Show failure message
    }
  };

  const handleCancel = () => {
    setShowConfirmation(false);
    setIsCancelling(true); // Show cancelling state
    setTimeout(() => {
      setIsCancelling(false); // Reset cancelling state after timeout
    }, 2000); // Adjust timeout duration as needed
  };

  const handleSnackbarClose = () => {
    setShowSuccess(false);
    setShowFailure(false);
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true); // Show confirmation popup
  };

  return (
    <>
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          onClose={() => setShowConfirmation(false)}
        />
      )}
      {showSuccess && (
        <AlertMessage
          message="Operation successful!"
          variant="success"
          onClose={handleSnackbarClose}
        />
      )}
      {showFailure && (
        <AlertMessage
          message="Operation failed!"
          variant="error"
          onClose={handleSnackbarClose}
        />
      )}
      {isCancelling && (
        <AlertMessage
          message="Cancelling..."
          variant="info" // Adjust the variant and message as per your design
          onClose={() => setIsCancelling(false)}
        />
      )}
      <button onClick={handleOpenConfirmation}>Open Confirmation</button>
      {/* Your main component content */}
    </>
  );
};

export default TestComponent;
