import "../App.css";
// import Login from "../auth/Login";
// import SignUp from "../auth/SignUp";
// import Dashboard from "../pages/Dashboard";
// import { Routes, Route } from "react-router-dom";
// import TestResults from "../pages/TestResults";
// import Navbar from "./Navbar";
// import UserProfile from "../pages/UserProfile";
// import ChangePassword from "../auth/ChangePassword";
// import ResetPassword from "../auth/ResetPassword";
// import Homepage from "../pages/Homepage";
// import MyCertificates from "../pages/MyCertificates";
// import CoursesList from "../pages/CoursesList";
// import ModulesList from "../pages/ModulesList";
// import Lesson from "../pages/Lesson";
// import ViewCertificate from "../pages/ViewCertificate";
// import ModuleReults from "../pages/ModuleResults";
// import UserManagement from "../pages/UserManagement";
// import CourseCreation from "../pages/AdCourseCreation";
// import CourseDetailsTab from "../pages/AdCourseDetailsTab";
// import CourseManagement from "../pages/AdCourseManagement";
// import CreateUserProfile from "../pages/AdCreateUserProfile";
// import AdminDashboard from "../pages/AdDashboard";
// import CourseLandingPage from "../pages/CourseLandingPage";
// import TestComponent from "./test";
// import useUserActivity from "../utils/userActivity";
// import CourseReportChart from "./reports/CourseReport";
// import OrganisationTree from "../pages/OrganisationTreeOuter";
// import Appraisal from "./appraisal/apprsl";
// import OverallPerformanceReview from "./appraisal/OverallPerformanceReview";
import useUserActivity from "../utils/userActivity";
import Router from "../Router/Router";
function App() {
  // Session Management

  // default time 15 mins
  // keeping 30 mins active time
  useUserActivity(30 * 60 * 1000); // activity tracker initialization
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
