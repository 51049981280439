import '../styling/AllCourses.css'
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Course1 from '../images/Course1.png';

export default function AllCourses({coursesList,setShowThumbnail, getCourseDetails,setStartCourse,setEnrollCourse})
{
    const location = useLocation();
    const [_, setUpdated] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
      // This will run every time the location changes
      setUpdated((prev) => !prev);
    }, [location]);

    const showModules =(courseID, courseTitle, courseBanner,courseStatus)=>
    {
        // passing the courseID and courseTitle to the thumbnail
        setShowThumbnail(true);
        getCourseDetails(courseID, courseTitle, courseBanner);
        // already course is assigned
        if (courseStatus === "Complete" || courseStatus === "In Progress" || courseStatus === "Pending") {
             setStartCourse(true);
             setEnrollCourse(false);
        } else {
            setStartCourse(false);
            setEnrollCourse(true);
        }
       
    }

    const printCertificate = (courseID) =>
    {
        // navigate("/view-certificate", { state: { courseID } });
        navigate("/my-certificates", { state: { courseID } });
    }
    return(
        <div className='all-courses-bg'>
            <hr className='course-divider '/>
            {
                
                coursesList?.map((course, key)=>
                {
                    return(
                        <>
                            <div className='all-courses' onClick={()=>showModules(course["Course_id"] || course["course_id"], course["Course_title"] || course["course_title"], course["Course_banner"] || course["course_banner"], course["Course_status"] || course["status"])}>
                                <div className='course-tile'>
                                    <img src={course["Course_banner"] || course["course_banner"]} alt="course1-cover"/>
                                </div>
                                <div>
                                    {/* <h2>{key+1}.{course["Course_title"] || course["course_title"]}</h2> */}
                                    <h2 dangerouslySetInnerHTML={{ __html: `${key+1}. ${course["Course_title"] || course["course_title"]}` }}></h2>

                                    {/* <p>{course["Course_desc"] || course["course_desc"]}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: course["Course_desc"] || course["course_desc"] }}></p>

                                    <div className='course-status'>
                                        {(course["Course_status"]==="Complete" || course["status"]==="Complete")?<button onClick={()=>printCertificate(course["Course_id"])}> Print Certificate </button>:null}
                                        <p>{course["Course_status"]}</p>
                                    </div>
                                </div>
                            </div>
                            <hr className='course-divider'/>
                        </>
                    )
                })
            }
        </div>
    )
}