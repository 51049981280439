import { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Updated for react-router-dom v6

const useUserActivity = (timeout = 15 * 60 * 1000) => {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory in react-router-dom v6
  let timer;

  const resetTimer = () => {
    clearTimeout(timer); // Clears the existing timeout
    timer = setTimeout(() => {
      // Sets a new timeout
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("role");
      localStorage.removeItem("permissions");
      navigate("/login"); // Redirect to login page
    }, timeout);
  };

  useEffect(() => {
    // List of events to listen for user activity
    const events = [
      "mousemove",
      "keydown",
      "mousedown",
      "touchstart",
      "scroll",
      "click",
    ];

    // Add event listeners for each event
    events.forEach((event) => window.addEventListener(event, resetTimer));

    resetTimer(); // Initialize timer on mount

    return () => {
      // Remove event listeners on cleanup
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      clearTimeout(timer); // Clean up the timer on unmount
    };
  }, [timeout]); // Add timeout to the dependency array
};

export default useUserActivity;
