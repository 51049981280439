import { useState, useEffect, useRef } from "react";
import "../styling/Profile.css";
import LockIcon from "../images/lock.png";
import UserSettingsIcon from "../images/userSettingsIcon.png";
import SignOutIcon from "../images/signOut.png";
import User from "../images/user.png";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { getHeaders } from "../components/utils/useAuth";
import { removeItem } from "../utils/storage";
import DP from "../images/man.png";
export default function Profile() {
  const [profilePic, setProfilePic] = useState(User);

  useEffect(() => {
    axiosInstance
      .get(`/users/user_details`)
      .then((res) => {
        setProfilePic(res.data["profile_image"]);
      })
      .catch((err) => {
        console.log("error occured", err);
      });
  }, []);
  // react hook to navigate to url
  const navigate = useNavigate();

  // state management
  const [showProfile, setShowProfile] = useState(false);

  // reference for profile div
  const profileRef = useRef();

  // effect for handling click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowProfile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  // handlers

  // user settings handler
  const userSettingHandler = () => {
    navigate("/user-settings");
  };

  // logout handler
  const LogoutHandler = () => {
    console.log("logout func:", localStorage.getItem("token"));
    // localStorage.removeItem("token");
    removeItem("token");
    removeItem("role");
    removeItem("permissions");

    navigate("/login");
  };

  return (
    <div
      ref={profileRef}
      className="user-profile-div"
      onClick={() => setShowProfile(!showProfile)}
    >
      <img src={profilePic || DP} alt="user-dp" />
      {showProfile && (
        <div className="profile-list">
          <div onClick={userSettingHandler}>
            <img src={UserSettingsIcon} alt="user-settings-icon" />
            <p> User Settings</p>
          </div>

          {/* <div>
            <img src={LockIcon} alt="lock-icon" />
            <p>Change Password</p>
          </div> */}

          <div onClick={LogoutHandler}>
            <img src={SignOutIcon} alt="sign-out-icon" />
            <p>Sign Out</p>
          </div>
        </div>
      )}
    </div>
  );
}
