import "../styling/UserManagement.css";
import AdminNavbar from "../components/admin/common/AdNavbar";
import UserManagementUtilities from "../components/admin/user-management/UserManagementUtilities";
import AdminLeftSideBar from "../components/admin/common/AdLeftSideBar";
import { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Typography, Box } from "@mui/material";
import * as XLSX from "xlsx";
import { getHeaders } from "../components/utils/useAuth";
import { getItem } from "../utils/storage";

// // Set up the headers for axios requests
// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
// };

export default function UserManagement() {
  // if token is expired, navigate to login
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  // State to manage the list of users and total number of users
  const [usersList, setUserList] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [role, setRole] = useState("");
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    // const userRole = localStorage.getItem("role");
    const userRole = getItem("role");
    setRole(userRole);

    // Fetch the list of users from the backend API
    axiosInstance
      .get(`/users`)
      .then((res) => {
        // Modify each user object to include a 'name' field combining first_name and last_name
        const modifiedUsers = res.data.map((user) => ({
          ...user,
          name: `${user.first_name} ${user.last_name}`,
        }));
        console.log(modifiedUsers);
        setUserList(modifiedUsers);
        setTotalUsers(modifiedUsers.length);
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
      });
  }, [location]);

  // Handler for the "Export" button
  const handleExportClick = () => {
    // Convert usersList to a worksheet
    const ws = XLSX.utils.json_to_sheet(usersList);
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    // Generate an Excel file and trigger a download
    XLSX.writeFile(wb, "users_data.xlsx");
  };

  // Handler for navigating to the user profile creation page
  const onUserClick = (userID) => {
    var role = getItem("role");

    if (role === "admin" || role === "manager") {
      navigate("/create-profile", { state: { userID } });
    } else {
      console.log(
        "Unauthorized access: Role does not permit profile creation."
      );
      // Optionally show a message or handle unauthorized access
    }
  };

  // Columns for the DataGrid
  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "empname", headerName: "Username", flex: 1 },
    { field: "designation", headerName: "Designation", flex: 1 },
    // { field: "role", headerName: "Role", flex: 1 },
    // {
    //   field: "special_permissions",
    //   headerName: "Special Permissions",
    //   flex: 1,
    // },
    {
      field: "is_active",
      headerName: "Status",
      flex: 1,
      cellClassName: "status-cell",
      renderCell: (params) => (
        <Typography color={params.value ? "green" : "red"}>
          {params.value ? "Active" : "Inactive"}
        </Typography>
      ),
    },
  ];

  return (
    <Box className="user-management-bg">
      {/* Sidebar component for admin navigation */}
      <AdminLeftSideBar />
      <Box className="um-right-section">
        {/* Navbar component for the admin dashboard */}
        <AdminNavbar />
        {/* Title for the user management section */}
        <Typography variant="h4" component="h1" paddingLeft={2}>
          User Management
        </Typography>

        {/* Utility component for user management */}
        <UserManagementUtilities totalUsers={totalUsers} />

        <Box className="usersList-container">
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.5vw",
            }}
          >
            <Typography
              variant="h6"
              component="p"
              className="usersList-title"
              style={{ marginLeft: "-0.75vw" }}
            >
              Users
            </Typography>
            <Button
              variant="contained"
              className="export-button"
              onClick={handleExportClick}
              disabled={role === "user" || role === "editor"}
              style={{ width: "5vw", height: "2vw", marginRight: "0.25vw" }}
            >
              Export
            </Button>
          </Box>

          <DataGrid
            rows={usersList}
            columns={columns}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            autoHeight
            className="data-grid"
            onRowClick={(params) => onUserClick(params.row.id)}
          />
        </Box>
      </Box>
    </Box>
  );
}
