import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import axiosInstance from "../../utils/axiosInstance"; // Keep axiosInstance for API calls
import "../../styling/appraisal/perfeval.css"; // Custom styles
import { useNavigate } from "react-router-dom";


const PerformanceEvaluation = ({ validate, employeeId, apprslId }) => {
  // Session check code
  const navigate = useNavigate();

  // if token is expired, navigate to login
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);
  const sections = [
    {
      title: "Knowledge of job skills *",
      items: [
        "Shows ability to learn and apply new skills",
        "Requires minimal supervision",
        "Displays understanding of roles and responsibilities",
      ],
    },
    {
      title: "Quality/Quantity of work *",
      items: [
        "Looks for ways to improve quality",
        "Performs full range of duties",
        "Meets required deadlines",
      ],
    },
    {
      title: "Customer Service Skills *",
      items: [
        "Asks questions to identify needs",
        "Quickly responds to help requests",
        "Displays ability to work in a team",
      ],
    },
    {
      title: "Attendance *",
      items: [
        "Gives notice for time off",
        "Shows up for work on time",
        "Arrives to meetings on time",
      ],
    },
    {
      title: "Initiative *",
      items: [
        "Fosters team collaboration",
        "Maintains personal work for quality",
        "Resolves problems early and quickly",
      ],
    },
    {
      title: "Inclusiveness *",
      items: [
        "Treats others with respect",
        "Displays empathy for others",
        "Includes others in team projects",
      ],
    },
  ];

  // State to keep track of the selected option for each item
  const [responses, setResponses] = useState({});

  // Fetch the existing data if apprslId is available
  useEffect(() => {
    if (apprslId) {
      axiosInstance
        .get(`/appraisals/performance_evaluation/${apprslId}`)
        .then((response) => {
          const data = response.data.performance_evaluation;
          if (data) {
            setResponses(data); // assuming API returns 'performance_evaluation'
          }
        })
        .catch((error) => {
          console.error("Error fetching performance evaluation data", error);
        });
    }
  }, [apprslId]);

  // Function to handle change for checkbox behavior
  const handleChange = (sectionTitle, item, option) => {
    setResponses((prev) => ({
      ...prev,
      [sectionTitle]: {
        ...prev[sectionTitle],
        [item]: option,
      },
    }));
  };

  // Function to render each evaluation row
  const renderEvaluationRow = (sectionTitle, item) => (
    <Grid container spacing={1} alignItems="center" key={item}>
      <Grid item xs={4}>
        <Typography sx={{ fontSize: "0.85vw", marginLeft: "1vw" }}>
          {item}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                responses[sectionTitle]?.[item] === "Succeed Expectation"
              }
              onChange={() =>
                handleChange(sectionTitle, item, "Succeed Expectation")
              }
            />
          }
          label={
            <Typography sx={{ fontSize: "0.85vw" }}>
              Succeed Expectation
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={responses[sectionTitle]?.[item] === "Meets Expectation"}
              onChange={() =>
                handleChange(sectionTitle, item, "Meets Expectation")
              }
            />
          }
          label={
            <Typography sx={{ fontSize: "0.85vw" }}>
              Meets Expectation
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={responses[sectionTitle]?.[item] === "Needs Improvement"}
              onChange={() =>
                handleChange(sectionTitle, item, "Needs Improvement")
              }
            />
          }
          label={
            <Typography sx={{ fontSize: "0.85vw" }}>
              Needs Improvement
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={responses[sectionTitle]?.[item] === "N/A"}
              onChange={() => handleChange(sectionTitle, item, "N/A")}
            />
          }
          label={<Typography sx={{ fontSize: "0.85vw" }}>N/A</Typography>}
        />
      </Grid>
    </Grid>
  );

  // Function to validate that all responses are recorded
  const validateForm = () => {
    for (const section of sections) {
      for (const item of section.items) {
        if (!responses[section.title] || !responses[section.title][item]) {
          alert(
            `Please fill the field "${item}" under the section "${section.title}".`
          );
          return false; // Return false if any item is missing a response
        }
      }
    }

    // If all responses are present, make PUT request to save the data
    const payload = { performance_evaluation: responses };

    axiosInstance
      .put(`/appraisals/${apprslId}`, payload)
      .then((response) => {
        console.log("Performance evaluation updated successfully", response);
      })
      .catch((error) => {
        console.error("Error updating performance evaluation", error);
      });

    return true; // All responses recorded
  };

  // UseEffect to set validation to passed ref
  useEffect(() => {
    validate.current = validateForm;
  }, [validate, responses]);

  return (
    <Box className="performance-evaluation-form">
      <Typography gutterBottom sx={{ fontSize: "1.25vw" }}>
        Performance Evaluation
      </Typography>

      <Box mb={2}>
        <Typography variant="body1" sx={{ fontSize: "1.15vw" }}>
          <strong>Rating Criteria:</strong>
        </Typography>
        <ul style={{ fontSize: "1vw" }}>
          <li>
            <strong>Succeeds Expectations:</strong> Performance consistently met
            expectations in all essential areas...
          </li>
          <li>
            <strong>Meets Expectations:</strong> Performance met and
            occasionally exceeded requirements...
          </li>
          <li>
            <strong>Needs Improvement:</strong> Performance failed to meet
            expectations in one or more essential areas...
          </li>
          <li>
            <strong>NA:</strong> Not Applicable
          </li>
        </ul>
      </Box>

      {sections.map((section) => (
        <Box key={section.title} mb={3}>
          <Typography
            variant="subtitle1"
            className="section-title"
            sx={{ fontSize: "0.95vw" }}
          >
            {section.title}
          </Typography>
          <Divider />
          {section.items.map((item) =>
            renderEvaluationRow(section.title, item)
          )}
        </Box>
      ))}
    </Box>
  );
};

export default PerformanceEvaluation;
