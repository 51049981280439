import { useState, useEffect } from "react";
import "../styling/CoursesList.css";
import Navbar from "../components/Navbar";
import CoursesTabs from "../components/CoursesTabs";
import axiosInstance from "../utils/axiosInstance";
import CourseThumbnail from "../components/CourseThumbnail";
import SideProfile from "../components/SideProfile";
import { useNavigate, useLocation } from "react-router-dom";
import { getHeaders } from "../components/utils/useAuth";
import AdminLeftSideBar from "../components/admin/common/AdLeftSideBar";
import AdminNavbar from "../components/admin/common/AdNavbar";

// const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };

const CoursesList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // if token is expired, navigate to login
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  // Get the tab value from state if available, otherwise default to "1"
  const defaultTab = location.state?.tab || "1";

  // console.log("***********************************************");
  // console.log("tab value received", defaultTab);
  // console.log("***********************************************");

  // State to manage the current tab value
  const [tabValue, setTabValue] = useState(String(defaultTab));
  // console.log("***********************************************");
  // console.log("tab value set", tabValue);
  // console.log("***********************************************");

  const [coursesList, setCoursesList] = useState();
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [startCourse, setStartCourse] = useState(false);
  const [enrollCourse, setEnrollCourse] = useState(false);
  const [allCoursesList, setAllCoursesList] = useState();
  const [courseID, setCourseID] = useState();
  const [courseTitle, setCourseTitle] = useState();
  const [courseBanner, setCourseBanner] = useState();
  const [completedCourses, setCompletedCourses] = useState(0);
  const getCourseDetails = (courseID, courseTitle, courseBanner) => {
    console.log("selected course id and title are: ", courseID, courseTitle);
    setCourseID(courseID);
    setCourseTitle(courseTitle);
    setCourseBanner(courseBanner);
  };

  useEffect(() => {
    // console.log(headers);
    axiosInstance
      .get(`/training/`)
      .then((res) => {
        console.log("setCoursesList==>");
        console.log("new courses API: ", res.data);
        setCoursesList(res.data);
        let i = 0;
        while (i < res.data.length) {
          console.log(res.data[i]["Course_status"]);
          if (res.data[i]["Course_status"] === "Complete") {
            setCompletedCourses(completedCourses + 1);
          }
          i++;
        }
      })
      .catch((err) => {
        console.log("there was error: ", err);
      });
  }, []);

  useEffect(() => {
    // console.log(headers);
    axiosInstance
      .get(`/enroll/courses/stat`)
      .then((res) => {
        console.log("set all courses list");
        console.log("new courses API: ", res.data);
        setAllCoursesList(res.data);
      })
      .catch((err) => {
        console.log("there was error: ", err);
      });
  }, []);

  // const navLinks = [
  //   { to: "/dashboard", label: "Dashboard" },
  //   { to: "/my-courses", label: "MyCourses" },
  //   // Add more links as needed
  // ];

  return (
    <div className="course-list-bg">
      <AdminLeftSideBar />
      <div className={`${showThumbnail ? "blur" : ""}`}>
        {/* <Navbar /> */}
        <AdminNavbar />
        {/* <NavigationBar navLinks={navLinks} /> */}
        <div className="coursesList-bg">
          <div className="content-section">
            <p>Course List</p>
            <CoursesTabs
              //
              tabValue={tabValue}
              coursesList={coursesList}
              setStartCourse={setStartCourse}
              setEnrollCourse={setEnrollCourse}
              // course stat api : courses list
              // all courses : assigned, available
              allCoursesList={allCoursesList}
              setShowThumbnail={setShowThumbnail}
              getCourseDetails={getCourseDetails}
              setCoursesList={setCoursesList}
            />
          </div>
          {/* <SideProfile completedCourses={completedCourses} /> */}
        </div>
      </div>
      {showThumbnail && (
        <CourseThumbnail
          courseID={courseID}
          courseTitle={courseTitle}
          setShowThumbnail={setShowThumbnail}
          courseBanner={courseBanner}
          startCourse={startCourse}
          enrollCourse={enrollCourse}
        />
      )}
    </div>
  );
};
export default CoursesList;
