import { useState, useEffect } from "react";
import Editor from "react-simple-wysiwyg";
import "../../../../styling/admin/AdAddCourseQuestions.css";
import { generateUniqueId } from "../../../utils";
import axiosInstance from "../../../../utils/axiosInstance";
import { getHeaders } from "../../../utils/useAuth";
import ConfirmationPopup from "../../../../utils/confirmationPopup";
import AlertMessage from "../../../../utils/alertMessage";
import OptionDelIcon from "../../../../images/admin/single_delete.png";

const initialState = {
  questionStatement: "",
  questionOptions: "",
  questionCorrectAnswer: ""
};

export default function AddCourseQuestions(props) {
  var { courseId, moduleId, lessonId, questionId } = props;
  const [editedQuestion, setEditedQuestion] = useState(props.editedQuestion);
  var [currentQuestionId, setCurrentQuestionId] = useState("");
  const [role, setRole] = useState("");

  const [answerOptionsList, setAnswerOptionsList] = useState(["","A"]);
  const [counter, setCounter] = useState(2);

  const [errorState, setErrorState] = useState(initialState);

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setRole(userRole);
  }, []);

  useEffect(() => {
    if (editedQuestion !== null) {
      setCurrentQuestionId(editedQuestion.id);
      setStatement(editedQuestion.question);
      
      setOptions({
        1: editedQuestion.options[0],}
      );

      let newElements = ['','A'];
      
      for(let i=1;i<editedQuestion.options.length;i++){
        
        let nextCharCode = newElements[i].charCodeAt(0) + 1;
       
        newElements.push(String.fromCharCode(nextCharCode));

        setOptions((options) => ({
          ...options,
          [i+1]: editedQuestion.options[i],
        }));
      }
      setAnswerOptionsList(newElements);

      // setOptions({
      //   1: editedQuestion.options[0],
      //   2: editedQuestion.options[1],
      //   3: editedQuestion.options[2],
      //   4: editedQuestion.options[3],
      //   5: editedQuestion.options[4],
      // });
      
      setCorrectAnswer(editedQuestion.answers[0]);
      let ctr = counter;
      setCounter(ctr + (editedQuestion.options.length - 1));
    } else if (editedQuestion === null) {
      setCurrentQuestionId(null);
      setStatement("");
      setOptions({
        1: "",
        // 2: "",
        // 3: "",
        // 4: "",
        // 5: "",
      });
      setCorrectAnswer("");
    }
  }, [editedQuestion]);

  const [questionData, setQuestionData] = useState({
    statement: "",
    // options: ["", "", "", "", ""],
    options: [""],
    correctAnswer: "",
  });

  const [statement, setStatement] = useState("");
  const [options, setOptions] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
  });
  const [correctAnswer, setCorrectAnswer] = useState("");

  const [editMode, setEditMode] = useState(false);
  const [previewData, setPreviewData] = useState({
    statement: "",
    question_type: "mcq",
    options: [""],
    // options: ["", "", "", "", ""],
    correctAnswer: "",
  });

  const [payloadData, setPayloadData] = useState({
    lesson_id: lessonId,
    statement: "",
    options: [""],
    // options: ["", "", "", "",""],
    correctAnswer: "",
  });

  const [confirmState, setConfirmState] = useState({
    open: false,
    message: "",
    onConfirm: null,
  });

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    variant: "success",
  });

  const handleChange = (value, field) => {
    setQuestionData({ ...questionData, [field]: value });
  };

  const handleEdit = () => {
    setEditMode(true);   
    if (previewData.statement !== "") {
      setStatement(previewData.statement);
      const newOptions = {};
      Object.entries(previewData.options).forEach(([key, value]) => {
        newOptions[key] = value;
      });
      setOptions(newOptions);
      setCorrectAnswer(previewData.correctAnswer);

      setPayloadData({
        statement: previewData.statement,
        options: Object.values(previewData.options),
        correctAnswer: previewData.correctAnswer,
      });
    }
    setPreviewData({
      statement: "",
      options: [""],
      // options: ["", "", "", "",""],
      correctAnswer: "",
    });
    setQuestionData({
      statement: "",
      options: [""],
      correctAnswer: "",
    });
  };

  const renderHTMLAsText = (html) => {
    return <span dangerouslySetInnerHTML={{ __html: html }} />;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!questionData.statement  && !questionData.correctAnswer && questionData.options.length == 1 &&  questionData.options[0] == ""){
      setAlertState({
        open: true,
        message: "Question and fields should be reviewd before saving.",
        variant: "info",
      });
      return false;
    }
    
    var questionPayload = {
      lesson_id: lessonId,
      question: questionData.statement,
      question_type: "",
      options:  Object.values(questionData.options),
      answers: [questionData.correctAnswer],
    };
    const confirmationMessage = editedQuestion
      ? "Update the " + statement + " question?"
      : "Save the  " + statement + " question?";
    const onConfirm = async () => {
      if (editedQuestion) {
        try {
          const res = await axiosInstance.put(
            `/questions/` + currentQuestionId,
            questionPayload
          );
          setAlertState({
            open: true,
            message: "Question updated successfully!",
            variant: "success",
          });
          props.navigateToQuestionsTab();
        } catch (err) {
          setAlertState({
            open: true,
            message: "Error updating question.",
            variant: "error",
          });
        }
      } else {
        try {
          const res = await axiosInstance.post(`/questions/`, questionPayload);
          setAlertState({
            open: true,
            message: "Question created successfully!",
            variant: "success",
          });
          props.navigateToQuestionsTab();
        } catch (err) {
          setAlertState({
            open: true,
            message: "Error creating question.",
            variant: "error",
          });
        }
      }
      setPreviewData({ ...questionData });
      setEditMode(false);
      setCurrentQuestionId(null);
      setEditedQuestion(null);
      setPreviewData({
        statement: "",
        options: [""],
        // options: ["", "", "", ""],
        correctAnswer: "",
      });
    };
    setConfirmState({
      open: true,
      message: confirmationMessage,
      onConfirm: onConfirm,
    });
  };

  const onChangeSetStatement = (e) => {
    setStatement(e.target.value);
  };

  const onChangeSetOptions = (optionKey, value) => {
    const optionValue = typeof value === "object" ? value.target.value : value;
    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionKey]: optionValue,
    }));

  };

  const onChangeSetCorrectAnswer = (e) => {
    setCorrectAnswer(e.target.value);
  };

  const onEnter = () => {
    setEditMode(false);
    const returnData = validateForm();
    if (returnData === true) {
      setQuestionData({
        statement: statement,
        options: Object.values(options),
        correctAnswer: correctAnswer,
      });
      setPayloadData({
        statement: statement,
        options: Object.values(options),
        correctAnswer: correctAnswer,
      });
      
      setStatement("");
      setOptions({
        1: "",
        // 2: "",
        // 3: "",
        // 4: "",
        // 5: ""
      });
      setCorrectAnswer("");
    }
  };

  const addOptions = () => {
    console.log("counter==>",counter)
    let ctr = counter;
    if(ctr < 6){
      console.log("counter==>",ctr)

      setOptions((options) => ({
        ...options,
        [ctr]: "",
      }));

      let nextCharCode =  answerOptionsList[ctr - 1].charCodeAt(0) + 1;
      
      setAnswerOptionsList([...answerOptionsList, String.fromCharCode(nextCharCode)]);
      
      setCounter(ctr + 1);

    }else{
      setAlertState({
        open: true,
        message: "Cannot add more than 5 options",
        variant: "info",
      });
    }
    
  };

  const  onDelOption = (optionKey) => {
    console.log("onDelOption counter==>",counter)
    let ctr = counter; 
    if(ctr > 2){
    
      const { [optionKey]: _, ...newOptions } = options;
      console.log("after delete options==>",newOptions);
      let newOptionsValue = {};
      let k = 1;

      Object.keys(newOptions).forEach(key => {
        const newKey = k;
        newOptionsValue[newKey] = newOptions[key];
        k++;
      });

      setOptions(newOptionsValue);

      setAnswerOptionsList((prevItems) => prevItems.slice(0, -1));  
      setCounter(ctr - 1);
      setCorrectAnswer("");
      console.log("onDelOption counter last==>",ctr)
      
    }else{
      setAlertState({
        open: true,
        message: "Cannot delete default option",
        variant: "info",
      });
    }
  };

  useEffect(() => {
    setPreviewData(questionData);
  }, [questionData]);


  const validateForm = () => {

    const errors = {};
    if(!statement){
      errors.questionStatement = "Please add question";
    }

    if(!correctAnswer){
      errors.questionCorrectAnswer = "Please select correct answer";
    }
    
    let optionValues = Object.values(options);
    let allOptions = true;
    for(let i=0;i<optionValues.length;i++){
      if(!optionValues[i]){
        console.log("in if of options",optionValues[i]);
        allOptions = false;
      }
    }

    if(allOptions === false){
      errors.questionOptions = "Please add text to options";
    }
    setErrorState(errors);

    
    if (Object.keys(errors).length > 0) {

        setAlertState({
          open: true,
          message: "Validation Error in the fields",
          variant: "info",
        });
  
      return false;
    }else{
      return true;
    }

  };


  return (
    <div className="course-questions-bg">
      <div className="sections-container">
        <div className="q-upper-section">
          <div className="upper-left-section">
            <h5>Questions
            {errorState.questionStatement && (
              <span className="error-msg" style={{ marginLeft : "1vw" }}>{errorState.questionStatement}</span>
            )}
            </h5>
            <Editor
              value={statement}
              onChange={onChangeSetStatement}
              disabled={role === "user" || role === "manager"}
            />
            
            <div className="horizontal-flex">
              <h5>Correct Answer</h5>
              
              {/* <Editor
                value={correctAnswer}
                onChange={onChangeSetCorrectAnswer}
                disabled={role === "user" || role === "manager"}
              /> */}
              <select style ={{height : "25px", marginTop : "12px", marginLeft: "5px"}}
                  value={correctAnswer}
                  onChange={onChangeSetCorrectAnswer}
                  disabled={role === "user" || role === "manager"}
                >
                  {answerOptionsList.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
              </select>
            </div>
            {errorState.questionCorrectAnswer && (
                <span className="error-msg" style={{ marginLeft : "1.5vw" }}>{errorState.questionCorrectAnswer}</span>
              )}
          </div>
          <div className="upper-right-section">
            <h5>Options
            {errorState.questionOptions && (
                <span className="error-msg" style={{ marginLeft : "1vw" }} >{errorState.questionOptions}</span>
            )} 
            </h5>
            
            {Object.entries(options).map(([optionKey, optionValue]) => (
              <div key={optionKey.toString()} style={{ display : "flex" ,flexDirection : "row-reverse"}} >
                <Editor
                  value={optionValue}
                  onChange={(value) => onChangeSetOptions(optionKey, value)}
                  disabled={role === "user" || role === "manager"}
                />
                <img src={OptionDelIcon} alt="Image" style={{position : "absolute" ,height :"13px",width : "13px",marginTop: "2px"}} onClick={() => onDelOption(optionKey)} />
              </div>
            ))}
            <div className="questions-enter-btn">
            <button
                onClick={onEnter}
                className={
                  role === "user" || role === "manager" ? "disabled" : ""
                }
                disabled={role === "user" || role === "manager"}
              >
                Enter
              </button>

            <button
                onClick={addOptions}
                className={
                  role === "user" || role === "manager" ? "disabled" : ""
                }
                disabled={role === "user" || role === "manager"}
              >
                Options
              </button>
              
            </div>
          </div>
        </div>
        <div className="q-lower-section">
          <h5>Review</h5>
          <div className="preview-data" style={{ fontWeight : "600",fontSize : "0.9vw"}}>
            <div>
              <span>Question statement : </span>
              {renderHTMLAsText(previewData.statement)}
            </div>
            <div>
              <span>Options : </span>
              <br></br>
              {Object.entries(previewData.options).map(
                ([optionKey, optionValue]) => (
                  <span key={optionKey}>
                    {parseInt(optionKey) + 1} : {renderHTMLAsText(optionValue)}
                    <br></br>
                  </span>
                )
              )}
            </div>
            <div>
              <span>Correct Answer : </span>
              {renderHTMLAsText(previewData.correctAnswer)}
            </div>
          </div>
        </div>
      </div>
      <div className="questions-save-btn">
        <button
          onClick={handleEdit}
          className={role === "user" || role === "manager" ? "disabled" : ""}
          disabled={role === "user" || role === "manager"}
        >
          Edit
        </button>
        <button
          onClick={handleSubmit}
          className={role === "user" || role === "manager" ? "disabled" : ""}
          disabled={role === "user" || role === "manager"}
        >
          Save
        </button>
      </div>
      {/* Confirmation Popup */}
      {confirmState.open && (
        <ConfirmationPopup
          message={confirmState.message}
          onConfirm={() => {
            confirmState.onConfirm();
            setConfirmState({ ...confirmState, open: false });
          }}
          onCancel={() => setConfirmState({ ...confirmState, open: false })}
          onClose={() => setConfirmState({ ...confirmState, open: false })}
        />
      )}

      {/* Single AlertMessage Component */}
      {alertState.open && (
        <AlertMessage
          message={alertState.message}
          variant={alertState.variant}
          onClose={() => setAlertState({ ...alertState, open: false })}
        />
      )}
    </div>
  );
}
