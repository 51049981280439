import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import "../styling/ModulesList.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import Course1 from "../images/Course1.png";
import { getHeaders } from "../components/utils/useAuth";
import AdminLeftSideBar from "../components/admin/common/AdLeftSideBar";
import AdminNavbar from "../components/admin/common/AdNavbar";

export default function ModulesList() {
  const navigate = useNavigate();
  const location = useLocation();
  const { courseID } = useParams();
  console.log("course id passed: ", courseID);
  const [moduleID, setModuleID] = useState();
  const [completedModules, setCompletedModules] = useState(0);
  const [data, setData] = useState();

  // console.log(location.state);
  // const data =
  // [
  //     {
  //       "Module_id": 1,
  //       "Module_title": "Module 1: Introduction to Data Protection",
  //       "Module_description": "Introduction to Data Protection Learn the basics of data protection, including its importance, key principles, types of data, and individual rights under GDPR. Gain insights into relevant regulations and how to navigate the evolving legal landscape."
  //     },
  //     {
  //       "Module_id": 2,
  //       "Module_title": "Module 2: Data Security and Breaches",
  //       "Module_description": "Data Security and Breaches Explore cybersecurity best practices, methods for protecting data, and steps for identifying and responding to data breaches effectively. Understand the importance of data minimization, retention policies, and proactive measures in ensuring data integrity and privacy."
  //     }
  //   ]

  const showLessons = (moduleID, moduleTitle, totalModules, moduleStatus) => {
    console.log(completedModules);
    setModuleID(moduleID);
    console.log("total modules: ", totalModules);
    axiosInstance
      .get(`/training/lessons/?module_id=${moduleID}`)
      .then((res) => {
        console.log("updated odule details are: ", [
          { courseID, moduleID, moduleTitle },
          ...res.data,
        ]);
        navigate(`/lesson/${moduleID}`, {
          state: [
            { courseID, moduleID, moduleTitle, totalModules, moduleStatus },
            ...res.data,
          ],
        });
      })
      .catch((err) => {
        console.log(
          "there was an error while fetching module's lessons details: ",
          err
        );
      });
  };
  useEffect(() => {
    axiosInstance
      .get(`/training/modules/?course_id=${courseID}`)
      .then((res) => {
        console.log("updated course content is : ", [
          location.state,
          ...res.data,
        ]);
        let i = 0;
        let count = 0;
        while (i < res.data.length) {
          if (res.data[i]["Status"] === "Complete") {
            console.log(count + 1);
            setCompletedModules(count + 1);
          }
          i++;
        }
        setData([location.state, ...res.data]);
      })
      .catch((err) => {
        console.log("There was an error while fetching course details: ", err);
      });
  }, []);

  // Function to create a sanitized HTML element
  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <div className="mdl-list-container-bg">
      <AdminLeftSideBar />
      {/* <Navbar /> */}
      <div className="modules-list-bg">
        <AdminNavbar />
        <p>
          <span
            dangerouslySetInnerHTML={createMarkup(
              `Course ${courseID}\t: ${location.state["courseTitle"]}`
            )} // Render HTML safely
          />
        </p>
        <div className="underline"></div>
        <hr />
        <div className="all-modules-bg">
          {data?.map((module, index) => {
            if (index > 0) {
              return (
                <>
                  <div
                    className={`all-modules ${
                      index <= completedModules + 1 ? "" : "disabled-course"
                    }`}
                    onClick={() =>
                      index <= completedModules + 1
                        ? showLessons(
                            module.Module_id,
                            module.Module_title,
                            data.length - 1,
                            module["Status"]
                          )
                        : {}
                    }
                  >
                    <div className="module-tile">
                      <img src={data[0]["courseBanner"]} alt="course1-cover" />
                    </div>
                    <div>
                      {/* <h2 id="module-heading">{module["Module_title"]}</h2> */}
                      <h2
                        id="module-heading"
                        dangerouslySetInnerHTML={{
                          __html: module["Module_title"],
                        }}
                      ></h2>
                      <p
                        id="module-description"
                        dangerouslySetInnerHTML={{
                          __html: module["Module_description"],
                        }}
                      ></p>
                      <p className="module-status">{module["Status"]}</p>
                    </div>
                  </div>
                  <hr className="course-divider1" />
                </>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
