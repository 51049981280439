import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AdminCourseCards from "./AdCourseCards";
import React, { useState } from "react";

// course management page tabs
// tabs to categorize  courses as all, assigned and drafts
export default function CoursesManagementTabs() {
  const [value, setValue] = useState("1");

  const handleChange = (_, tabValue) => {
    setValue(tabValue);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        typography: "body1",
        display: "flex",
        flexDirection: "column",
        // height: "77.75vh",
        height: "39.55vw",
        // marginLeft:"1vw"
      }}
    >
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange}>
            <Tab label="All" value="1"></Tab>
            <Tab label="Approved" value="2"></Tab>
            <Tab label="Not Approved" value="3"></Tab>
            <Tab label="Rejected" value="4"></Tab>
          </TabList>
        </Box>

        <TabPanel value="1" sx={{ backgroundColor: "#D9D9D9", height: "70vh" }}>
          <AdminCourseCards status="All" />
        </TabPanel>
        <TabPanel value="2" sx={{ backgroundColor: "#D9D9D9", height: "70vh" }}>
          <AdminCourseCards status="Approved" />
        </TabPanel>
        <TabPanel value="3" sx={{ backgroundColor: "#D9D9D9", height: "70vh" }}>
          <AdminCourseCards status="Not Approved" />
        </TabPanel>
        <TabPanel value="4" sx={{ backgroundColor: "#D9D9D9", height: "70vh" }}>
          <AdminCourseCards status="Rejected" />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
