import React from "react";
import "../../styling/training/CourseLandingPage.css";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Checkbox,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { getHeaders } from "../utils/useAuth";

const CourseActionAssignComponent = ({ CourseID,coursesList,setCoursesList,allCoursesList }) => {
  const [unAssignedUserList, setUnAssignedUserList] = useState([]);
  const [assignedUserList, setAssignedUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTermUnassigned, setSearchTermUnassigned] = useState("");
  const [searchTermAssigned, setSearchTermAssigned] = useState("");
  const [filteredUnassignedUsers, setFilteredUnassignedUsers] = useState([]);
  const [filteredAssignedUsers, setFilteredAssignedUsers] = useState([]);

  // Load assigned and unassigned users at the start
  useEffect(() => {
    fetchUnassignedUsers();
    fetchAssignedUsers();
    setSelectedUsers([]);
  }, [CourseID]);

  // Fetch unassigned users
  const fetchUnassignedUsers = () => {
    axiosInstance
      .get(`/enroll/unassigned/${CourseID}`)
      .then((res) => {
        setUnAssignedUserList(res.data);
        setFilteredUnassignedUsers(res.data); // Update filtered users
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Fetch assigned users
  const fetchAssignedUsers = () => {
    axiosInstance
      .get(`/enroll/assigned/${CourseID}`)
      .then((res) => {
        setAssignedUserList(res.data);
        setFilteredAssignedUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Assign course to selected users
  const assignCourseToUsers = (toAssign) => {
    const payload = {
      emp_ids: toAssign,
      course_id: CourseID,
    };

    axiosInstance
      .post(`/enroll/assign/special`, payload)
      .then((res) => {
        fetchUnassignedUsers();
        fetchAssignedUsers();
        setSelectedUsers([]); // Clear selected users
        
        let loogedUserId = localStorage.getItem("loggedInUserId");
        let flag = false;
        for(let i=0;i<toAssign.length;i++){
          if(toAssign[i] == loogedUserId){
            flag = true;
          }
        }

        if(flag === true){
          axiosInstance
            .get(`/training/`)
            .then((res) => {
              console.log("setCoursesList==>");
              console.log("new courses API: ", res.data);
              setCoursesList(res.data);
            })
            .catch((err) => {
              console.log("there was error: ", err);
            });
        }
     })
      .catch((err) => {
        console.log(err);
      });
  };

  // Unassign course from selected users
  const unassignCourseFromUsers = (toUnassign) => {
    const payload = {
      emp_ids: toUnassign,
    };

    console.log("##########################");
    console.log("payload passed");
    console.log(payload);
    console.log("header printing that we are passing", getHeaders());
    console.log("##########################");

    axiosInstance
      .delete(`/enroll/remove/` + CourseID, {
        data: payload,
      })
      .then((res) => {
        fetchUnassignedUsers();
        fetchAssignedUsers();
        setSelectedUsers([]); // Clear selected users

        let loogedUserId = localStorage.getItem("loggedInUserId");
        let flag = false;
        for(let i=0;i<toUnassign.length;i++){
          if(toUnassign[i] == loogedUserId){
            flag = true;
          }
        }

        if(flag === true){
          axiosInstance
            .get(`/training/`)
            .then((res) => {
              console.log("setCoursesList==>");
              console.log("new courses API: ", res.data);
              setCoursesList(res.data);
            })
            .catch((err) => {
              console.log("there was error: ", err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Handler for toggling user selection
  const handleToggle = (userId) => () => {
    const currentIndex = selectedUsers.indexOf(userId);
    const newSelectedUsers = [...selectedUsers];

    if (currentIndex === -1) {
      newSelectedUsers.push(userId);
    } else {
      newSelectedUsers.splice(currentIndex, 1);
    }

    setSelectedUsers(newSelectedUsers);
  };

  // Handler for assigning selected users
  const handleAssignClick = () => {
    assignCourseToUsers(selectedUsers);
  };

  // Handler for unassigning selected users
  const handleUnassignClick = () => {
    unassignCourseFromUsers(selectedUsers);
  };

  // Handler for search input change in unassigned list
  const handleSearchChangeUnassigned = (event) => {
    const searchTerm = event.target.value;
    setSearchTermUnassigned(searchTerm);

    const filteredUsers = unAssignedUserList.filter(
      (user) =>
        user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUnassignedUsers(filteredUsers);
  };

  // Handler for search input change in assigned list
  const handleSearchChangeAssigned = (event) => {
    const searchTerm = event.target.value;
    setSearchTermAssigned(searchTerm);

    const filteredUsers = assignedUserList.filter(
      (user) =>
        user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredAssignedUsers(filteredUsers);
  };

  // Handler for cancel button
  const handleCancelClick = () => {
    setSelectedUsers([]);
  };

  return (
    <div className="tab-div-bg-asgn">
      <div className="asgn-cont">
        <div className="asgn-cont-left">
          <Box
            sx={{
              border: 1,
              borderColor: "grey.400",
              padding: "0.5rem",
              marginBottom: "1rem",
              minWidth: "12vw",
              maxWidth: "12vw",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontSize: "0.85vw", marginBottom: "0.25vw" }}
            >
              Unassigned Employee
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Search users..."
              size="small"
              fullWidth
              value={searchTermUnassigned}
              onChange={handleSearchChangeUnassigned}
              sx={{
                marginBottom: "0.5rem",
                "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
                  maxHeight: "2vw",
                  minHeight: "2vw",
                },
              }}
            />
            <List
              dense
              component="div"
              style={{
                maxHeight: "5.5vw",
                minHeight: "5.5vw",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {filteredUnassignedUsers.map((user) => (
                <ListItem key={user.id} onClick={handleToggle(user.id)}>
                  <Checkbox
                    edge="start"
                    checked={selectedUsers.indexOf(user.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    sx={{ transform: "scale(0.8)" }}
                  />
                  <ListItemText
                    primary={
                      user.first_name +
                      " " +
                      user.last_name +
                      "(" +
                      user.email +
                      ")"
                    }
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontSize: "0.75vw",
                        fontWeight: 400,
                        fontFamily: "Arial, sans-serif",
                        margin: 0,
                        lineHeight: 1.43,
                        letterSpacing: "0.01071em",
                        display: "block",
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{
              maxWidth: "3vw",
              maxHeight: "1.25vw",
              minHeight: "1.25vw",
              marginLeft: "0vw",
              fontSize: "0.75vw",
            }}
            onClick={handleAssignClick}
          >
            Assign
          </Button>
          <Button
            variant="contained"
            color="grey"
            style={{
              maxWidth: "3vw",
              maxHeight: "1.25vw",
              minHeight: "1.25vw",
              marginLeft: "1vw",
              fontSize: "0.75vw",
            }}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </div>
        <div className="asgn-cont-right">
          <Box
            sx={{
              border: 1,
              borderColor: "grey.400",
              padding: "0.5rem",
              marginBottom: "1rem",
              minWidth: "12vw",
              maxWidth: "12vw",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontSize: "0.85vw", marginBottom: "0.25vw" }}
            >
              Assigned
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Search users..."
              size="small"
              fullWidth
              value={searchTermAssigned}
              onChange={handleSearchChangeAssigned}
              sx={{
                marginBottom: "0.5rem",

                "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
                  maxHeight: "2vw",
                  minHeight: "2vw",
                },
              }}
            />
            <List
              dense
              component="div"
              style={{
                maxHeight: "5.5vw",
                minHeight: "5.5vw",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {filteredAssignedUsers.map((user) => (
                <ListItem key={user.id} onClick={handleToggle(user.id)}>
                  <Checkbox
                    edge="start"
                    checked={selectedUsers.indexOf(user.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    sx={{ transform: "scale(0.8)" }}
                  />
                  <ListItemText
                    primary={
                      user.first_name +
                      " " +
                      user.last_name +
                      " (" +
                      user.email +
                      ")"
                    }
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontSize: "0.75vw",
                        fontWeight: 400,
                        fontFamily: "Arial, sans-serif",
                        margin: 0,
                        lineHeight: 1.43,
                        letterSpacing: "0.01071em",
                        display: "block",
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            style={{
              maxWidth: "3vw",
              maxHeight: "1.25vw",
              minHeight: "1.25vw",
              marginLeft: "0vw",
              fontSize: "0.75vw",
            }}
            onClick={handleUnassignClick}
          >
            Unassign
          </Button>
          <Button
            variant="contained"
            color="grey"
            style={{
              maxWidth: "3vw",
              maxHeight: "1.25vw",
              minHeight: "1.25vw",
              marginLeft: "0.5vw",
              fontSize: "0.75vw",
            }}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CourseActionAssignComponent;
