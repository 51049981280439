import { useState } from 'react';
import '../styling/MiddleSection.css';
import DP1 from '../images/DP1.png';
import DP2 from '../images/DP2.png';
import DP3 from '../images/DP3.png';
import Bell from '../images/Bell.png';
import box3Img from '../images/box3Img.png';
import box6Img from '../images/box6Img.png';
import TimerIcon from '../images/TimerIcon.png';
import CareerIcon  from '../images/CareerIcon.png';
import empDetailsIcon from '../images/EmpDetailsIcon.png';
import CompensationIcon from '../images/CompensationIcon.png';
import PolicyIcon from '../images/PolicyIcon.png';
import LearningIcon from '../images/LearningIcon.png';

const new_hires =
[
    {
        DP: DP1,
        name:"Sadikshya S."
    },
    {
        DP: DP2,
        name:"Sajith N."
    },
    {
        DP: DP3,
        name:"Anshuman B."
    }
]
const quickLinks = 
[
    {
        img: TimerIcon,
        name:"Time Tracker"
    },
    {
        img: CareerIcon,
        name:"Career Conversations"
    },
    {
        img: CompensationIcon,
        name:"Compensation & Bonus"
    },
    {
        img: empDetailsIcon,
        name:"Employee Details "
    },
    {
        img: PolicyIcon,
        name:"Company Policy"
    },
    {
        img: LearningIcon,
        name:"Learning Portal"
    },
]

export default function MiddleSection()
{
    const [newHires, setNewHires] = useState(new_hires);

    return(
        <div className='middle-bar-bg'>
            <div className='upper-middle'>
                <p>Announcements</p>
                <div>
                    <div className='col'>
                        <div id='box1'>
                            <span>New Hires</span>
                            {
                                newHires.map((emp, ind)=>
                                {
                                    return(
                                        <div>
                                            <img className="empDP" src={emp.DP} alt="employee_DP"/>
                                            <span className="empName">{emp.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div id='box2'>
                            <span>Policy Updates</span>
                            <div>
                                <img className="bell-icon" src={Bell} alt="bell-icon"/>
                                <span className="notification-header">Notifications</span>
                            </div>
                        </div>
                    </div>

                    <div className='col'>
                        <div id='box3'>
                            <img  src={box3Img} alt='box3Img'/>
                            <h4>Recent Mergers/Wins</h4>
                            <p>Talk about wins here</p>
                        </div>
                        <div id='box4'>
                            <div>
                                <h4>Company Milestones</h4>
                                <p>Copperpod turns 2!</p>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                        </div>
                    </div>

                    <div className='col'>
                        <div id='box5'>
                            <p>Software Updates</p>
                            <div>
                                <img className="bell-icon" src={Bell} alt="updates"/>
                                <span>1 Update Available</span>
                            </div>
                        </div>
                        <div id='box6'>
                            <img src={box6Img} alt='box6Img'/>
                            <h4>Employee Spotlight</h4>
                            <p>Sadikshya S. </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className='lower-middle'>
            {
                quickLinks.map((link, ind) =>
                {
                    return(
                    <div>
                        <div>
                            <img src={link.img} alt={`quick link ${ind}`}/>
                        </div>
                        <p>{link.name}</p>
                    </div>
                    )
                    
                })
            }
            </div>
        </div>
    )
}