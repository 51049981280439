import "../styling/Homepage.css";
import LeftSidebar from "../components/LeftSidebar";
import MiddleSection from "../components/MiddleSection";
import RightSidebar from "../components/RightSidebar";
import DP3 from "../images/DP3.png";
import settingIcon from "../images/settingIcon.png";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { getHeaders } from "../components/hooks/useAuth";

export default function Homepage() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="homepage-bg">
      <div className="greeting-div">
        <p>Welcome to,</p>
        <h2>Copperpod Digital People Portal</h2>
      </div>

      <div id="profile-div">
        <img className="user-pp" src={DP3} alt="user-profile-icon" />
        <p>Anshuman B</p>
        <div>
          <img src={settingIcon} alt="setting-icon" />
        </div>
      </div>

      <LeftSidebar />
      <MiddleSection />
      <RightSidebar />
    </div>
  );
}
