import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "../styling/ViewCertificate.css";
import { saveAs } from "file-saver";
import { useNavigate, useLocation } from "react-router-dom";
import DownloadIcon from "../images/download_certificate_icon.png";
import certificateTemplate from "../images/certificate_template.png";
import axiosInstance from "../utils/axiosInstance";
import { getHeaders } from "../components/utils/useAuth";
import axios from "axios";

export default function ViewCertificate() {
  const navigate = useNavigate();

  // if token is expired, navigate to login
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  // useEffect(() => {
  //   axiosInstance
  //     .get(`/users/user_details`)
  //     .then((res) => {})
  //     .catch((err) => {
  //       console.log("error occured", err);
  //     });
  // }, []);

  const location = useLocation();
  const [certImg, setCertImg] = useState();
  const [name, setName] = useState();

  const handleDownload = (CertImg) => {
    // Creating an 'a' element
    const link = document.createElement("a");
    link.href = CertImg; // URL of the image
    link.download = "certificate.png"; // Desired file name
    // link.target = "_blank"; // Opens in a new tab to avoid navigation

    // Append the 'a' element to the body (required for Firefox)
    document.body.appendChild(link);

    // Programmatically click the 'a' element to trigger the download
    link.click();

    // Remove the 'a' element after download
    document.body.removeChild(link);
  };

  console.log(`certificate for course ${location.state.courseID}`);
  const handleClose = () => {
    navigate("/my-certificates");
  };

  useEffect(() => {
    // const headers = {
    //   Authorization: `Bearer ${localStorage.getItem("token")}`,
    // };

    const courseID = location.state.courseID;
    const url = `/training/generate_certificate/?course_id=${courseID}`;

    // const url = `/training/generate_certificate/?course_id=${location.state.courseID}`;

    console.log("=====================");
    console.log(url);
    console.log("=====================");

    axiosInstance
      .get(url)
      // axiosInstance.get(`/training/all_certificates`, {headers})

      .then((res) => {
        console.log("this is the image certificate! ", res.data);
        setCertImg(res.data["certificate_url"]);
        const arr1 = res.data["certificate_url"].split("/");
        const name = arr1[arr1.length - 2];
        const arr2 = name.split("_");
        setName(arr2[0] + "\t" + arr2[1]);
      })
      .catch((err) => {
        console.log("Sorry, there is no certificate for you.");
      });
  }, []);

  return (
    <>
      <Navbar />
      <div className="view-certificate-container">
        <div className="header">
          <span>
            {name} Course {location.state.courseID} Certificate
          </span>

          <div className="buttons-section">
            <button
              className="download-button"
              onClick={() => handleDownload(certImg)}
            >
              {/* <img src={DownloadIcon} alt="download-certificate"/> */}
              <span>Download</span>
            </button>
            <button className="close-button" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>

        <div className="certificate-image">
          <img src={certImg} alt="Certificate" />
        </div>
      </div>
    </>
  );
}
