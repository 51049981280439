import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styling/Login.css";
import UserIcon from "../../images/user.png";
import PadLockIcon from "../../images/padlock.png";
import CPD_Logo from "../../images/CPD_Logo.png";
import axiosInstance from "../../utils/axiosInstance";
import { setItem, removeItem } from "../../utils/storage";
import AlertMessage from "../../utils/alertMessage";
import { useEditorState } from "react-simple-wysiwyg";

export default function LoginComponent() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    variant: "success",
  });

  useEffect(() => {
    removeItem("token");
    removeItem("refreshToken");
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    const payload = `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`;

    try {
      const res = await axiosInstance.post(`/auth/user_token`, payload);
      const accessToken = res.data.access_token;
      const refreshToken = res.data.refresh_token;
      setItem("token", accessToken);
      setItem("refreshToken", refreshToken);
      setItem("username", username);

      const userRes = await axiosInstance.get(`/users/user_details`);
      setItem("role", userRes.data["role"]);
      setItem("permissions", userRes.data["special_permissions"]);
      setItem("loggedInUserId", userRes.data["id"]);

      setAlertState({
        open: true,
        message: "Login successful!",
        variant: "success",
      });

      setTimeout(() => {
        setAlertState({
          ...alertState,
          open: false,
        });

        if (userRes.data.role === "user" || userRes.data.role === "manager") {
          navigate("/dashboard");
        } else {
          navigate("/dashboard", {
            state: { first_name: userRes.data.first_name },
          });
        }
      }, 1000); // Hide success message after 2 seconds
    } catch (err) {
      setAlertState({
        open: true,
        message: "Login failed! Please enter valid credentials.",
        variant: "error",
      });
      setError("Please enter valid credentials.");
    }
  };

  return (
    <div className="login-bg">
      <div>
        <img className="cpd-logo" src={CPD_Logo} alt="CPD Logo" />
      </div>

      <form className="login-form" onSubmit={submitHandler}>
        <h2 className="login-header">LOGIN</h2>
        {error ? (
          <p className="error-msg">{error}</p>
        ) : (
          <p className="login-desc">Please enter login ID and password</p>
        )}

        <div id="email-bar">
          <input
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <img src={UserIcon} alt="User Icon" />
        </div>
        <div id="password-bar">
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <img src={PadLockIcon} alt="Padlock Icon" />
        </div>

        <div className="login-fgt-pwd">
          Reset Password?
          <Link to="/reset-password">
            <i>Reset Password</i>
          </Link>
        </div>

        <button className="login-btn" type="submit">
          Login
        </button>

        {/* <p className="register-opt">
          Not a member?
          <Link to="/register">
            <i> Register</i>
          </Link>
        </p> */}
      </form>

      {/* Single AlertMessage Component */}
      {alertState.open && (
        <AlertMessage
          message={alertState.message}
          variant={alertState.variant}
          onClose={() => setAlertState({ ...alertState, open: false })}
        />
      )}
    </div>
  );
}
