import "../../../styling/AdminNavbar.css";
import Profile from "../../Profile";
import NotifIconDot from "../../../images/Notif Icon Dot.png";
import NotifIcon from "../../../images/notif icon.png";

export default function AdminNavbar() {
  return (
    <div className="admin-navbar-bg">
      {/* <div className="adminNotif-bg">
        <img src={NotifIconDot} alt="notification-dot" />
      </div> */}
      <div className="profile-div">
        <Profile />
      </div>
    </div>
  );
}
