import "../styling/SubmitModal.css";
import TestResults from "../pages/TestResults";
import { useNavigate } from "react-router";
import axiosInstance from "../utils/axiosInstance";
import { getHeaders } from "./utils/useAuth";

export default function SubmitModal(props) {
  const {
    courseDetails,
    markedAns,
    totalQues,
    attemptedQues,
    showModal,
    setShowModal,
  } = props;
  const navigate = useNavigate();
  console.log(attemptedQues, " out of ", totalQues);
  const submitQuizHandler = () => {
    const reqData = {
      course_id: Number(courseDetails.courseID),
      module_id: courseDetails.moduleID,
      answers: markedAns,
    };
    axiosInstance
      .post(`/training/get_module_score`, reqData)
      .then((res) => {
        console.log("answer submitted! ", res.data);
        // {  "status": "success",  "percent": 23.81,  "verdict": "fail" }
        // if(courseDetails.moduleID==courseDetails.totalModules)
        // {
        //     navigate(`/testresult`, {state:res.data});
        // }
        // else
        // {
        //     navigate(`/module-results`, {state:{...res.data,courseDetails}});
        // }
        navigate(`/module-results`, {
          state: { ...res.data, courseDetails },
        });
      })
      .catch((err) => {
        console.log(err, "Sorry there was an error while submitting the quiz");
      });
  };

  return (
    <div className="submit-modal-bg">
      <div>
        <h1>Submit Module?</h1>
        <p>Are you sure you want to submit your course?</p>
        {/* {
                attemptedQues==totalQues ? <>:
                    <p>Are you sure you want to Submit? You still have some <span style={{color:"#E20000"}}>unanswered questions!</span></p>
                } */}

        <div className="submit-btns">
          <button id="submit-proceed" onClick={submitQuizHandler}>
            Proceed
          </button>
          <button id="submit-cancel" onClick={() => setShowModal(!showModal)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
