import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { BrowserRouter, BrowserRouter as Router } from "react-router-dom";
import { GlobalStateProvider } from "../src/components/GlobalStateContext";
import { Provider } from 'react-redux'
import store from "./store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalStateProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </GlobalStateProvider>
);
