/**
 *
 * @param {string} key
 * @param {any} value
 */
export const setItem = (key, value) => {
  try {
    // localStorage.setItem(key, JSON.stringify(value));
    localStorage.setItem(key, value);
  } catch (error) {
    console.error(`Error setting item in localstorage : ${error}`);
  }
};

/**
 * Get an item from localStorage
 * @param {string} key
 * @param {any} value
 * @returns
 */
export const getItem = (key, value) => {
  try {
    const item = localStorage.getItem(key);
    // return item ? JSON.parse(item) : null;
    return item ? item : null;
  } catch (error) {
    console.error(`Error getting item from localstorage : ${error}`);
    return null;
  }
};

/**
 * Remove an item from localStorage
 * @param {string} key
 */
export const removeItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing item from localstorage : ${error}`);
  }
};

/**
 * Clear all items from localstorage
 */
export const clearStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.error(`Error clearing localstorage: ${error}`);
  }
};
