import React from "react";
import { Snackbar, SnackbarContent, Button } from "@mui/material";

const ConfirmationPopup = ({ message, onConfirm, onCancel, onClose }) => {
  const handleConfirm = () => {
    onConfirm();
    onClose(); // Close the popup after confirmation
  };

  const handleCancel = () => {
    onCancel();
    onClose(); // Close the popup after cancellation
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={true} // Toggle this based on when you want to show the popup
      autoHideDuration={null} // Set to null to disable auto-dismissal
    >
      <SnackbarContent
        sx={{
          backgroundColor: "#f0f0f0", // Background color of the SnackbarContent
          color: "#333", // Text color
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px", // Padding around the content
        }}
        message={message}
        action={
          <>
            <Button
              sx={{
                color: "#666",
                borderColor: "#666",
                marginRight: "8px", // Margin between Cancel and Confirm buttons
                transition: "background-color 0.3s", // Smooth transition on hover
                "&:hover": {
                  backgroundColor: "#e0e0e0", // Light grey on hover for Cancel button
                },
              }}
              size="small"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#4caf50",
                color: "#fff",
                transition: "background-color 0.3s", // Smooth transition on hover
                "&:hover": {
                  backgroundColor: "#45a049", // Darker green on hover for Confirm button
                },
              }}
              size="small"
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </>
        }
      />
    </Snackbar>
  );
};

export default ConfirmationPopup;
