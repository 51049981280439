import { useState } from "react";
import "../styling/CourseThumbnail.css";
import CourseCover from "../images/courseCover.png";
import axiosInstance from "../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../images/CloseIcon.png";
import { getHeaders } from "./utils/useAuth";
import { Tab } from "@mui/material";

export default function CourseThumbnail({
  courseID,
  courseTitle,
  setShowThumbnail,
  courseBanner,
  startCourse,
  enrollCourse,
}) {
  const navigate = useNavigate();

  const attemptCourse = (courseID, courseTitle, courseBanner) => {
    navigate(`/moduleLists/${courseID}`, {
      state: { courseTitle, courseBanner },
    });
  };

  const courseEnrollment = (courseID) => {
    var payload = {
      course_id: courseID,
    };
    console.log("============================");
    console.log("header value");
    console.log(getHeaders());
    console.log("payload value");
    console.log(payload);
    console.log("============================");
    axiosInstance
      .post(`/enroll/assign`, payload)
      .then((res) => {
        console.log("Course enrollment response");
        console.log("response recieved");
        console.log(res.data);
        navigate(`/my-courses`, { state: { tab: 2 } });
        setShowThumbnail(false);
      })
      .catch((err) => {
        console.log("error enrolling into course", err);
      });
  };

  return (
    <div className="thumbnail-bg">
      <div className="title-div">
        <img src={courseBanner} />
        <p dangerouslySetInnerHTML={{ __html: courseTitle }}></p>
      </div>

      <div className="right-div">
        <p>Welcome to this course on:</p>
        <p dangerouslySetInnerHTML={{ __html: courseTitle }}></p>

        {/* <button className='resume'>Resume</button> */}
        {/* <button className='start' onClick={e => attemptCourse(courseID, courseTitle,courseBanner)}> Start</button> */}
        {startCourse && (
          <button
            className="start"
            onClick={(e) => attemptCourse(courseID, courseTitle, courseBanner)}
          >
            {" "}
            Get started
          </button>
        )}
        {enrollCourse && (
          <button className="start" onClick={(e) => courseEnrollment(courseID)}>
            {" "}
            Enroll to course
          </button>
        )}
      </div>

      <img
        className="close-thumbnail"
        src={CloseIcon}
        alt="close-thumbnail"
        onClick={() => setShowThumbnail(false)}
      />
      {/* <div className="close-button">
                <i className="fa fa-times" aria-hidden="true"></i>
            </div> */}
    </div>
  );
}
