import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { getItem } from "../../utils/storage";
import ReactQuill from "react-quill";
import SignatureCanvas from "react-signature-canvas";
import axiosInstance from "../../utils/axiosInstance";
import { useSelector, useDispatch } from "react-redux";
import "react-quill/dist/quill.snow.css";
import "../../styling/appraisal/cmntsgoals.css";
import { setIsDataUploaded } from './AppraisalReducer'

const CommentsGoalsForm = ({ validate, employeeId, apprslId }) => {
  const dispatch = useDispatch()
  const [comments, setComments] = useState("");
  const [goals, setGoals] = useState("");
  const [employeeSignature, setEmployeeSignature] = useState(null);
  const [managerSignature, setManagerSignature] = useState(null);
  const [employeeSignaturePreview, setEmployeeSignaturePreview] = useState("");
  const [managerSignaturePreview, setManagerSignaturePreview] = useState("");
  const [overAllRating, setOverAllRating] = useState("");
  const [evaluationSubmissionDate, setEvaluationSubmissionDate] = useState("");
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [currentSignatureType, setCurrentSignatureType] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const employeeSignatureRef = useRef();
  const managerSignatureRef = useRef();
  const start_date = useSelector(
    (state) => state.appraisalSlice.eval_start_date
  );


  // Fetch appraisal data
  useEffect(() => {
    console.log(start_date);
    const fetchAppraisalData = async () => {
      try {
        const response = await axiosInstance.get(
          `/appraisals/comment/${apprslId}`
        );
        const {
          comments,
          goals_for_next_review,
          employee_signature,
          manager_signature,
          overall_ratings,
          submission_date,
        } = response.data;

        setOverAllRating(overall_ratings);
        setEvaluationSubmissionDate(submission_date);
        setComments(comments || "");
        setGoals(goals_for_next_review || "");

        if (employee_signature) {
          setEmployeeSignaturePreview(employee_signature);
        }
        if (manager_signature) {
          setManagerSignaturePreview(manager_signature);
        }
      } catch (error) {
        console.error("Error fetching appraisal data:", error);
      }
    };

    fetchAppraisalData();
  }, [apprslId]);

  // Validation and Submission function
  const validateForm = () => {
    // Validation checks
    // Extract plain text without HTML tags
    const quillcomments = document
      .querySelector(".quill-container .ql-editor")
      .innerText.trim();
    const quillgoals = document
      .querySelectorAll(".quill-container .ql-editor")[1]
      .innerText.trim();

    if (!quillcomments) {
      alert("Please enter comments.");
      return false;
    }
    if (!quillgoals) {
      alert("Please enter goals.");
      return false;
    }
    if (!employeeSignaturePreview) {
      alert("Please upload the employee's signature.");
      return false;
    }
    if (!managerSignaturePreview) {
      alert("Please upload the manager's signature.");
      return false;
    }

    // If all validations pass, prepare payload
    const payload = {
      comments,
      goals_for_next_review: goals,
      submission_date: evaluationSubmissionDate,
      overall_ratings: overAllRating,
      employee_signature: employeeSignaturePreview,
      manager_signature: managerSignaturePreview,
    };
    console.log(payload, "payload");
    axiosInstance
      .put(`/appraisals/${apprslId}`, payload)
      .then((res) => {
        console.log("PUT response:", res); // Log the PUT response
        if (res.status >= 200 && res.status < 300) {
          dispatch(setIsDataUploaded(true))
          alert("Appraisal data updated successfully!");
          return true;
          // showAlert("Appraisal data updated successfully!");
        }
      })
      .catch((err) => {
        console.log(payload);
        console.error("Error occurred during appraisal update:", err);
        alert("Failed to update appraisal data.");

        // showAlert("Failed to update appraisal data.");
        return false;
      });

    return true;
  };

  // Set validation function to the passed ref
  useEffect(() => {
    validate.current = validateForm;
  }, [
    validate,
    comments,
    goals,
    employeeSignaturePreview,
    managerSignaturePreview,
  ]);

  const handleSignatureClick = (type) => {
    setCurrentSignatureType(type);
    setOpenSignatureDialog(true);
  };

  const saveSignature = async () => {
    const signatureRef =
      currentSignatureType === "employee"
        ? employeeSignatureRef.current
        : managerSignatureRef.current;

    if (signatureRef && !signatureRef.isEmpty()) {
      const signatureData = signatureRef.toDataURL();
      setUploadProgress(0);

      try {
        await axiosInstance.put(
          `/appraisals/${apprslId}`,
          { signature: signatureData, type: currentSignatureType },
          {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setUploadProgress(progress);
            },
          }
        );

        if (currentSignatureType === "employee") {
          setEmployeeSignaturePreview(signatureData);
        } else {
          setManagerSignaturePreview(signatureData);
        }

        alert("Signature uploaded successfully!");
      } catch (error) {
        console.error("Error uploading signature:", error);
        alert("Failed to upload signature.");
      }
    }

    setOpenSignatureDialog(false);
  };

  const clearSignature = () => {
    const signatureRef =
      currentSignatureType === "employee"
        ? employeeSignatureRef.current
        : managerSignatureRef.current;
    if (signatureRef) {
      signatureRef.clear();
    }
  };

  const handleCheckboxChange = (rating) => {
    setOverAllRating(rating);
  };

  return (
    <Box className="comments-goals-form">
      <Typography gutterBottom sx={{ fontSize: "1.25vw" }}>
        Comments & Goals
      </Typography>

      <Box mb={2}>
        {/* Comments Section */}
        <Box mb={4}>
          <Typography
            variant="subtitle1"
            className="editor-label"
            style={{ fontSize: "1vw" }}
          >
            Comments *
          </Typography>
          <div className="quill-container">
            <ReactQuill
              value={comments}
              readOnly={getItem("role") == "user"}
              onChange={setComments}
              placeholder="Enter description"
              style={{ height: "100px" }}
            />
          </div>
        </Box>

        {/* Goals for next review */}
        <Box mb={4}>
          <Typography
            variant="subtitle1"
            className="editor-label"
            style={{ fontSize: "1vw", marginTop: "4vw" }}
          >
            Goals for next review *
          </Typography>
          <div className="quill-container">
            <ReactQuill
              value={goals}
              readOnly={getItem("role") == "user"}
              onChange={setGoals}
              placeholder="Enter description"
              style={{ height: "100px" }}
            />
          </div>
        </Box>

        <Grid
          container
          spacing={2}
          style={{
            border: "1px solid #000",
            height: "10vw",
            width: "72.75vw",
            marginTop: "4vw",
            marginLeft: "0.05vw",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "-3vw",
            }}
          >
            <Typography
              variant="subtitle1"
              className="editor-label"
              style={{ fontSize: "1vw", paddingTop: "3vw" }}
            >
              Over all rating *
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", gap: "5vw" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "1vw",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "0.85vw" }}>
                  Succeed Expectations
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={getItem("role") == "user"}
                      checked={overAllRating === "Succeed Expectations"}
                      onChange={() =>
                        handleCheckboxChange("Succeed Expectations")
                      }
                    />
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "1vw",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "0.85vw" }}>
                  Meet Expectation
                </Typography>
                <FormControlLabel
                  style={{ display: "flex", flexDirection: "column" }}
                  control={
                    <Checkbox
                      disabled={getItem("role") == "user"}
                      checked={overAllRating === "Meet Expectation"}
                      onChange={() => handleCheckboxChange("Meet Expectation")}
                    />
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "1vw",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "0.85vw" }}>
                  Need Improvement
                </Typography>
                <FormControlLabel
                  style={{ display: "flex", flexDirection: "column" }}
                  control={
                    <Checkbox
                      disabled={getItem("role") == "user"}
                      checked={overAllRating === "Need Improvement"}
                      onChange={() => handleCheckboxChange("Need Improvement")}
                    />
                  }
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "-3vw",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="subtitle1"
                className="editor-label"
                style={{
                  fontSize: "1vw",
                  paddingTop: "3vw",
                  paddingBottom: "1.5vw",
                }}
              >
                Submission date *
              </Typography>
              <TextField
                disabled={getItem("role") == "user"}
                label="Submission date"
                type="date"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: start_date, // Disable all previous dates from the specified min date
                }}
                variant="outlined"
                size="small"
                fullWidth
                value={evaluationSubmissionDate}
                onChange={(e) => setEvaluationSubmissionDate(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Signature Upload Section */}
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography
              variant="subtitle1"
              style={{ fontSize: "1vw", marginTop: "3vw" }}
            >
              Employee Signature *
            </Typography>
            {employeeSignaturePreview && (
              <Box mt={2}>
                <img
                  src={employeeSignaturePreview}
                  alt="Employee Signature Preview"
                  className="signature-preview"
                />
                <Button
                  variant="outlined"
                  onClick={() => handleSignatureClick("employee")}
                  style={{
                    marginTop: "-1.5vw",
                    marginLeft: "0.5vw",
                    fontSize: "0.75vw",
                  }}
                >
                  Sign Again
                </Button>
              </Box>
            )}
            {!employeeSignaturePreview && (
              <Button
                variant="contained"
                onClick={() => handleSignatureClick("employee")}
                style={{ marginTop: "1vw" }}
              >
                Sign
              </Button>
            )}
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="subtitle1"
              style={{ fontSize: "1vw", marginTop: "3vw" }}
            >
              Manager Signature *
            </Typography>
            {managerSignaturePreview && (
              <Box mt={2}>
                <img
                  src={managerSignaturePreview}
                  alt="Manager Signature Preview"
                  className="signature-preview"
                />
                <Button
                  variant="outlined"
                  onClick={() => handleSignatureClick("manager")}
                  style={{
                    marginTop: "-1.5vw",
                    marginLeft: "0.5vw",
                    fontSize: "0.75vw",
                  }}
                >
                  Sign Again
                </Button>
              </Box>
            )}
            {!managerSignaturePreview && (
              <Button
                variant="contained"
                onClick={() => handleSignatureClick("manager")}
                style={{ marginTop: "1vw" }}
              >
                Sign
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* Signature Dialog with Progress Animation */}
      <Dialog
        open={openSignatureDialog}
        onClose={() => setOpenSignatureDialog(false)}
      >
        <DialogTitle style={{ fontSize: "1vw" }}>
          Sign {currentSignatureType === "employee" ? "Employee" : "Manager"}{" "}
          Signature
        </DialogTitle>
        <DialogContent>
          <SignatureCanvas
            penColor="black"
            ref={
              currentSignatureType === "employee"
                ? employeeSignatureRef
                : managerSignatureRef
            }
            canvasProps={{
              // width: 400,
              // height: 200,
              className: "sigCanvas",
              style: {
                border: "1px solid #000",
                width: "30vw",
                height: "15vw",
              },
            }}
          />
          {uploadProgress > 0 && (
            <Box mt={2}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          )}
        </DialogContent>
        <DialogActions
          style={{ marginRight: "1vw", marginBottom: "0vw", marginTop: "-1vw" }}
        >
          <Button
            onClick={clearSignature}
            style={{
              border: "1px solid #000",
              width: "2vw",
              height: "2vw",
              fontSize: "0.85vw",
            }}
          >
            Clear
          </Button>
          <Button
            onClick={saveSignature}
            style={{
              border: "1px solid #000",
              width: "2vw",
              height: "2vw",
              fontSize: "0.85vw",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CommentsGoalsForm;
