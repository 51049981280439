import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    eval_start_date : "",
    isDataUploaded : Boolean
}


const appraisalSlice = createSlice({
    name: 'appraisalSlice',
    initialState,
    reducers:{
        setEvalStartDate : (state, action) => {
            state.eval_start_date = action.payload
        },
        setIsDataUploaded : (state, action) => {
            state.isDataUploaded = action.payload
        }
    }
})

export const { setEvalStartDate, setIsDataUploaded } = appraisalSlice.actions;
export default appraisalSlice.reducer;
