import React from "react";
import { Snackbar, SnackbarContent } from "@mui/material";
import { useEffect, useState } from "react";
const AlertMessage = ({ message, variant, onClose }) => {
  const [open, setOpen] = useState(true);
  // Automatically close the Snackbar after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
      onClose(); // Call onClose to handle any additional cleanup or actions
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{ alignItems: "center" }}
      open={true} // Toggle this based on when you want to show the message
      autoHideDuration={3000} // Duration in milliseconds before auto-dismissal
      onClose={onClose}
    >
      <SnackbarContent
        message={message}
        style={{
          backgroundColor: variant === "success" ? "#4caf50" : "#f44336",
          alignItems: "center",
        }}
        sx={{ alignItems: "center" }}
      />
    </Snackbar>
  );
};

export default AlertMessage;
