import React from "react";
import "../../../styling/UserManagementUtilities.css";
import DropDownIcon from "../../../images/DropdownIcon.png";
import PlusIcon from "../../../images/Icon Plus.png";
import FilterIcon from "../../../images/filter.png";
import ExportIcon from "../../../images/export.png";
import Search from "../../Search";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";

// import { utils, writeFile } from "xlsx";

const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };

export default function UserManagementUtilities(props) {
  const navigate = useNavigate();
  const [role, setRole] = useState("");

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setRole(userRole);
  }, []);

  return (
    <div className="user-utilities-bg">
      <div className="left-utilities">
        {/* <div
          onClick={() => role === "admin" && navigate("/create-profile")}
          disabled={role !== "admin"}
        >
          <img id="plus-icon" src={PlusIcon} alt="create icon" />
          <span>Create User</span>
        </div> */}
        {role === "admin" && (
          <div onClick={() => navigate("/create-profile")}>
            <img id="plus-icon" src={PlusIcon} alt="create icon" />
            <span>Create User</span>
          </div>
        )}
        <p>Total Users: {props.totalUsers}</p>
      </div>

      <div className="right-utilities">
        {/* <Search />
        <div>
          <span>Filter</span>
          <img id="filter-icon" src={FilterIcon} alt="filter icon" />
        </div>
        <div onClick={handleExport} style={{ cursor: "pointer" }}>
          {" "}
          <span style={{ color: "white" }}>Export</span>
          <img id="export-icon" src={ExportIcon} alt="export icon" />
        </div> */}
      </div>
    </div>
  );
}
