import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AdminNavbar from "../components/admin/common/AdNavbar";
// import '../../styling/CourseManagement.css';
// import '../../styling/admin/AdCourseDetails.css';
import "../styling/admin/AdCourseCreation.css";

// import CourseDetailsTab from "./CourseDetailsTab";
import CourseDetailsTab from "./AdCourseDetailsTab";
import CourseStructureTab from "../components/admin/course-management/AdCourseStructureTab";
// import CourseStructureTabTemp from "../AdCourseStructureTab";
import AdminLeftSideBar from "../components/admin/common/AdLeftSideBar";
import { useLocation } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { getHeaders } from "../components/utils/useAuth";
import { useState } from "react";
import AlertMessage from "../utils/alertMessage";

export default function CourseCreation() {
  const location = useLocation();
  const courseId = location.state?.courseId;
  const [title, setTitle] = useState();
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    variant: "success",
  });

  useEffect(() => {
    console.log("value of courseid", courseId);
    axiosInstance
      .get(`/courses/` + courseId)
      .then((res) => {
        setTitle(res.data["title"]);
      })
      .catch((err) => {
        console.log("error fetching course details", err);
      });
  }, [courseId]);

  const [value, setValue] = React.useState("1");

  const handleChange = (e, tabValue) => {
    if (tabValue === "2" && !courseId) {
      setAlertState({
        open: true,
        message: "Please add/save course details first!",
        variant: "error",
      });
      return;
    }
    setValue(tabValue);
  };

  return (
    <div className="course-creation-bg">
      {/* <div className="left-section">Left Sidebar</div> */}
      <AdminLeftSideBar />
      <div className="right-section">
        <AdminNavbar />
        <span>{title}</span>
        {/* <div className="course-create-bg"> */}
        <Box sx={{ width: "100%", typography: "body1", marginTop: "0.75vw" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Details" value="1" />
                <Tab label="Structure" value="2" />
              </TabList>
            </Box>

            <TabPanel value="1">
              {/* <AllCourses /> */}
              <CourseDetailsTab courseId={courseId} />
            </TabPanel>
            <TabPanel value="2">
              {/* <CourseStructureTab courseId={courseId} /> */}
              <CourseStructureTab courseId={courseId} />
              {/* <AllCourses coursesList={coursesList?.filter(course => course["Course_status"]==='Pending' || course["Course_status"]==='In Progress')} setShowThumbnail={setShowThumbnail} getCourseDetails={getCourseDetails}/> */}
            </TabPanel>
          </TabContext>
        </Box>
        {/* </div> */}
      </div>
      {/* Single AlertMessage Component */}
      {alertState.open && (
        <AlertMessage
          message={alertState.message}
          variant={alertState.variant}
          onClose={() => setAlertState({ ...alertState, open: false })}
        />
      )}
    </div>
  );
}
