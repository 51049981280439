import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import "../styling/TestResults.css";
import { useNavigate, useLocation } from "react-router-dom";
import Close from "../images/CloseIcon.png";
import axiosInstance from "../utils/axiosInstance";

export default function TestResults() {
  const navigate = useNavigate();
  const location = useLocation();
  const [courseTitle, setCourseTitle] = useState();

  console.log("last module result: ", location.state);

  const { course_percent, course_verdict, courseID } = location.state;

  useEffect(() => {
    axiosInstance
      .get("/courses/" + courseID)
      .then((res) => {
        setCourseTitle(res.data["title"]);
      })
      .catch((err) => {
        console.log("error while retrieving courses", err);
      });
  });
  const checkCourseResults = () => {
    if (course_verdict == "fail") navigate("/my-courses");
    // else navigate("/view-certificate", { state: { courseID } });
    else navigate("/my-certificates", { state: { courseID } });
  };

  return (
    <>
      <Navbar />
      <div className="result-bg">
        <div className="result-box">
          <img
            src={Close}
            alt="close-test-results"
            onClick={() => navigate(-3)}
          />
          <h1>{courseTitle}</h1>
          {course_verdict == "fail" ? (
            <h2 style={{ color: "red" }}>Try Again!</h2>
          ) : (
            <h2 style={{ color: "#47A30F" }}>Congratulations!</h2>
          )}
          <h3>You have scored {course_percent}% overall!</h3>
          <button className="print-certificate" onClick={checkCourseResults}>
            {course_verdict == "fail" ? "Try Again" : "Print Certificate"}
          </button>
        </div>
      </div>
    </>
  );
}
