import { useState, useEffect } from "react";
import "../styling/Cards.css";
import CoursesIcon from "../images/coursesIcon.png";
import CertificateIcon from "../images/certificateIcon.png";
import CourseActionIcon from "../images/courseActionIcon.png";
import axiosInstance from "../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
// import { getHeaders } from "./utils/useAuth";

export default function Cards({ CoursesList }) {
  const [role, setRole] = useState("User");
  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setRole(userRole);
  });
  const [assignedCourses, setAssignedCourses] = useState(0);
  const [completedCourses, setCompletedCourses] = useState(0);
  const [pendingCourses, setPendingCourses] = useState(0);
  const [certificateCount, setCertificateCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let i = 0;
    while (CoursesList != null && i < CoursesList.length) {
      setAssignedCourses(CoursesList.length);
      if (CoursesList[i]["Course_status"] === "Complete")
        setCompletedCourses((prevCompleted) => prevCompleted + 1);
      // else
      //     setPendingCourses(pendingCourses+1)
      i++;
    }
  }, [CoursesList]);

  // useEffect(()=>
  // {
  //     const headers  = {Authorization: `Bearer ${localStorage.getItem('token')}`};
  //     axiosInstance.get(`/training/all_certificates`)
  //     .then( res  =>
  //     {
  //         // console.log("this is the image certificate! ", res.data);
  //         setCertificateCount(res.data["all_certificates"].filter( course => {
  //             course[Course_status] ==
  //         }));
  //     })
  //     .catch(err =>
  //     {
  //         console.log("Sorry, there is no certificate for you.");
  //     })
  // }, [])

  return (
    <div className="cards-bg">
      <div
        className="card"
        onClick={() => navigate("/my-courses", { state: { tab: 2 } })}
      >
        <div className="card-header">
          <img src={CoursesIcon} />
          <h2>Courses</h2>
        </div>

        <hr />

        <div className="courses-status">
          <div>
            <p>
              <strong>{assignedCourses}</strong>
            </p>
            <p>Assigned</p>
          </div>

          <div>
            <p>
              <strong>{assignedCourses - completedCourses}</strong>
            </p>
            <p>In-progress</p>
          </div>

          <div>
            <p>
              <strong>{completedCourses}</strong>
            </p>
            <p>Completed</p>
          </div>
        </div>
      </div>

      <div className="card" onClick={() => navigate("/my-certificates")}>
        <div className="card-header">
          <img src={CertificateIcon} />
          <h2>Certificates</h2>
        </div>

        <hr />

        <div className="courses-status">
          <div>
            <p>
              <strong>{completedCourses}</strong>
            </p>
            <p>Total</p>
          </div>
        </div>
      </div>

      {/* <div className="card" onClick={() => navigate("/organisation-tree")}>
        <div className="card-header">
          <h2>Organisation Tree</h2>
        </div>

        <hr />

        <div className="courses-status" style={{visibility : "hidden"}} >
          <div>
            <p>
              <strong>{completedCourses}</strong>
            </p>
            <p>Total</p>
          </div>
        </div>
      </div> */}

      {(role === "admin" || role === "editor") && (
        <div className="card" onClick={() => navigate("/admin-dashboard")}>
          <div className="card-header">
            <img src={CourseActionIcon} />
            <h2>Admin</h2>
          </div>

          <hr />

          <div className="courses-status">
            <div>
              <p> </p>
            </div>
          </div>
        </div>
      )}
      {/* <div className="card" onClick={() => navigate("/admin-dashboard")}>
        <div className="card-header">
          <img src={CourseActionIcon} />
          <h2>Admin Dashboard</h2>
        </div>

        <hr />

        <div className="courses-status">
          <div>
            <p> </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}
