import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AllCourses from "./AllCourses";
import CourseLandingPage from "../pages/CourseLandingPage";
import { useEffect, useState } from "react";

export default function CoursesTabs({
  tabValue,
  coursesList,
  setStartCourse,
  setEnrollCourse,
  allCoursesList,
  setShowThumbnail,
  getCourseDetails,
  setCoursesList
}) {
  const [value, setValue] = useState("1");

  useEffect(() => {
    setValue(String(tabValue)); // Update state when prop changes
  }, [tabValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="All Courses" value="1" />
            <Tab label="Assigned[Enrolled]" value="2" />
            <Tab label="Completed" value="3" />
            <Tab label="In-progress" value="4" />
          </TabList>
        </Box>

        <TabPanel value="1">
          {/* <AllCourses
            coursesList={allCoursesList}
            setShowThumbnail={setShowThumbnail}
            getCourseDetails={getCourseDetails}
            setStartCourse={setStartCourse}
            setEnrollCourse={setEnrollCourse}
          /> */}
          <CourseLandingPage
            setShowThumbnail={setShowThumbnail}
            getCourseDetails={getCourseDetails}
            setStartCourse={setStartCourse}
            setEnrollCourse={setEnrollCourse}
            coursesList={coursesList}
            setCoursesList={setCoursesList}
            allCoursesList={allCoursesList}
          />
        </TabPanel>
        <TabPanel value="2">
          <AllCourses
            coursesList={coursesList}
            setShowThumbnail={setShowThumbnail}
            getCourseDetails={getCourseDetails}
            setStartCourse={setStartCourse}
            setEnrollCourse={setEnrollCourse}
          />
        </TabPanel>
        <TabPanel value="3">
          <AllCourses
            coursesList={coursesList?.filter(
              (course) => course["Course_status"] === "Complete"
            )}
            setShowThumbnail={setShowThumbnail}
            getCourseDetails={getCourseDetails}
            setStartCourse={setStartCourse}
            setEnrollCourse={setEnrollCourse}
          />
        </TabPanel>
        <TabPanel value="4">
          <AllCourses
            coursesList={coursesList?.filter(
              (course) => course["Course_status"] === "In Progress"
            )}
            setShowThumbnail={setShowThumbnail}
            getCourseDetails={getCourseDetails}
            setStartCourse={setStartCourse}
            setEnrollCourse={setEnrollCourse}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
