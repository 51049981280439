import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Cards from "../components/Cards";
import "../styling/Dashboard.css";
import AssignedCourses from "../components/AssignedCourses";
import CourseThumbnail from "../components/CourseThumbnail";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, Box, Typography, AppBar } from "@mui/material"; // Import Material-UI components
import CourseEnrollment from "../components/training/CourseEnrollment";

import { getHeaders } from "../components/utils/useAuth";
import NavigationBar from "../components/utils/useNavigation";
import axiosInstance from "../utils/axiosInstance";
import { getItem } from "../utils/storage";
import AdminLeftSideBar from "../components/admin/common/AdLeftSideBar";
import AdminNavbar from "../components/admin/common/AdNavbar";

export default function Dashboard() {
  // useEffect(() => {
  //   axiosInstance
  //     .get(`/users/user_details`)
  //     .then((res) => {
  //       console.log(
  //         "user details fetched by new local storage set and axios interceptor header add",
  //         res.data
  //       );
  //     })
  //     .catch((error) => {
  //       console.log("error occured", error);
  //     });
  // });
  const navigate = useNavigate();
  const [CoursesList, setCoursesList] = useState();
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [startCourse, setStartCourse] = useState(false);
  const [enrollCourse, setEnrollCourse] = useState(false);
  const [courseID, setCourseID] = useState();
  const [courseTitle, setCourseTitle] = useState();
  const [courseBanner, setCourseBanner] = useState(0);
  const [token, setToken] = useState();
  const [selectedTab, setSelectedTab] = useState(1); // State to manage selected tab index

  const getCourseDetails = (courseID, courseTitle, courseBanner) => {
    console.log("selected courseID is=", courseID);
    setCourseID(courseID);
    setCourseTitle(courseTitle);
    setCourseBanner(courseBanner);
  };

  useEffect(() => {
    // const auth_token = localStorage.getItem("token");
    const auth_token = getItem("token");
    console.log("auth-token is :", auth_token);
    if (!auth_token) navigate("/login");
    else setToken(auth_token);
  }, []);

  useEffect(() => {
    if (token) {
      axiosInstance
        .get(`/training/`)
        .then((res) => {
          console.log("new courses API: ", res.data);
          // const appendedList = [
          //   ...res.data,
          //   ...res.data,
          //   ...res.data,
          //   ...res.data,
          // ];
          setCoursesList(res.data);
          // setCoursesList(appendedList);
        })
        .catch((err) => {
          console.log("there was error: ", err);
        });
    }
  }, [token]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // const navLinks = [
  //   { to: "/homepage", label: "Homepage" },
  //   { to: "/dashboard", label: "Dashboard" },
  //   { to: "/login", label: "Login" },
  //   // Add more links as needed
  // ];

  return (
    <div className="dashboard-container">
      <AdminLeftSideBar />
      <div className={`${showThumbnail ? "blur" : ""}`}>
        <AdminNavbar />
        {/* <NavigationBar links={navLinks} /> */}
        <div className="dashboard-bg">
          <div>
            <h1 className="welcome-text"> Welcome to the Copperpod </h1>
            <h1 className="welcome-text"> Digital Learning Academy!</h1>
            <p className="welcome-desc">Learn something new today! </p>
          </div>
          <Cards CoursesList={CoursesList} />
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Dashboard Tabs"
          >
            <Tab label="Assigned Courses" />
            <Tab label="Available Courses" />
          </Tabs>

          {/* Render content based on selected tab */}
          <TabPanel value={selectedTab} index={0}>
            {CoursesList && (
              <AssignedCourses
                CoursesList={CoursesList}
                setShowThumbnail={setShowThumbnail}
                getCourseDetails={getCourseDetails}
                setStartCourse={setStartCourse}
                setEnrollCourse={setEnrollCourse}
              />
            )}
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            {/* {CoursesList && ( */}
            <CourseEnrollment
              // CoursesList={CoursesList}
              setShowThumbnail={setShowThumbnail}
              getCourseDetails={getCourseDetails}
              setStartCourse={setStartCourse}
              setEnrollCourse={setEnrollCourse}
            />
            {/* )} */}
          </TabPanel>
        </div>
      </div>
      {showThumbnail && (
        <CourseThumbnail
          courseID={courseID}
          courseTitle={courseTitle}
          setShowThumbnail={setShowThumbnail}
          courseBanner={courseBanner}
          startCourse={startCourse}
          enrollCourse={enrollCourse}
        />
      )}
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children} {/* Adjust padding as needed */}
        </Box>
      )}
    </div>
  );
}
