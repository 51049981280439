import "../styling/RightSidebar.css";
import ArrowTopRight from "../images/arrow-top-right-normal.png";
import GoalsIcon from "../images/GoalsIcon.png";

const RightUpperSection = () => {
  return (
    <div className="right-upper-section">
      <div className="events-box">
        <span>Upcoming Events</span>
      </div>
      <div className="event-notification">
        <p>Town Hall on the Horizon! 20-04-2024</p>
      </div>
    </div>
  );
};

function onboardingTasksHandler() {}

const OnboardingTasksComponent = () => {
  const onboardingTasks = [
    { label: "Task 1", submihandler: onboardingTasksHandler },
    { label: "Task 2", submihandler: onboardingTasksHandler },
    { label: "Task 3", submihandler: onboardingTasksHandler },
    { label: "Task 4", submihandler: onboardingTasksHandler },
    { label: "Task 5", submihandler: onboardingTasksHandler },
  ];
  return (
    <div className="task-box">
      <span>Onboarding Tasks</span>
      <div className="scrollable-list">
        {onboardingTasks.map((tasks) => (
          <button>
            <span>{tasks.label}</span>
            <img src={ArrowTopRight} alt="arrow-top-right" />
          </button>
        ))}
      </div>
    </div>
  );
};

function trainingSessionsHandler() {}

const TrainingSessionsComponent = () => {
  const trainingSessions = [
    { label: "Session 1", submihandler: trainingSessionsHandler },
    { label: "Session 2", submihandler: trainingSessionsHandler },
    { label: "Session 3", submihandler: trainingSessionsHandler },
    { label: "Session 4", submihandler: trainingSessionsHandler },
    { label: "Session 5", submihandler: trainingSessionsHandler },
  ];

  return (
    <div className="task-box">
      <span>Training Sessions</span>
      <div className="scrollable-list">
        {trainingSessions.map((session) => (
          <button>
            <span>{session.label}</span>
            <img src={ArrowTopRight} alt="arrow-top-right" />
          </button>
        ))}
      </div>
    </div>
  );
};

function golasHandler() {}

const GoalsComponent = () => {
  const goals = [
    { label: "Goal 1", submihandler: golasHandler },
    { label: "Goal 2", submihandler: golasHandler },
    { label: "Goal 3", submihandler: golasHandler },
    { label: "Goal 4", submihandler: golasHandler },
    { label: "Goal 5", submihandler: golasHandler },
  ];
  return (
    <div className="task-box">
      <span>Goals</span>
      <div className="scrollable-list">
        {goals.map((goal) => (
          <button>
            <span>{goal.label}</span>
            <img src={GoalsIcon} alt="goal-icon" />
          </button>
        ))}
      </div>
    </div>
  );
};

const RightLowerSection = () => {
  return (
    <div className="right-lower-section">
      <span>Task List</span>
      <div className="taskbox-container">
        <OnboardingTasksComponent />
        <TrainingSessionsComponent />
        <GoalsComponent />
      </div>
    </div>
  );
};

export default function RightSidebar() {
  return (
    <div className="right-sidebar-bg">
      <RightUpperSection />
      <RightLowerSection />
    </div>
  );
}
