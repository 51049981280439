import React, { useEffect, useState } from "react";
import "../../styling/training/CourseLandingPage.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { getHeaders } from "../utils/useAuth";
import axiosInstance from "../../utils/axiosInstance";

const CourseActionApprovalComponent = ({ CourseID ,handleCourseSelect }) => {
  const [courseStatus, setCourseStatus] = useState("");

  useEffect(() => {
    const urlFormatted = `/courses/` + CourseID;
    console.log("=====================");
    console.log("course id received in action approval component", CourseID);
    console.log("formatted url", urlFormatted);
    console.log("=====================");

    axiosInstance
      .get(urlFormatted)
      .then((res) => {
        console.log("course details fetched");
        console.log(res.data);
        setCourseStatus(res.data["status"]);
      })
      .catch((err) => {
        console.log("Error fetching course data", err);
      });
  }, [CourseID]);

  const updateCourseStatus = (status) => {
    axiosInstance
      .put(`/approval/course/` + CourseID + `/status`, { status: status })
      .then((res) => {
        console.log("course status updated", res.data);
        setCourseStatus(status);
        handleCourseSelect(CourseID);
      })
      .catch((err) => {
        console.log("Error updating course status", err);
      });
  };

  const handleStatusChange = (status) => {
    console.log(`${status} button clicked`);
    updateCourseStatus(status);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Approved":
        return "green";
      case "Rejected":
        return "red";
      case "Not Approved":
      default:
        return "grey";
    }
  };

  return (
    <div className="tab-div-bg">
      <Box display="flex" flexDirection="column" gap={2}>
        <div
          style={{
            marginLeft: "0vw",
            marginTop: "1vw",
            fontWeight: "bold",
          }}
        >
          Current Status:{" "}
          <span style={{ color: getStatusColor(courseStatus) }}>
            {courseStatus}
          </span>
        </div>
        {courseStatus === "Approved" && (
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "#C5C5C5", color: "white" }}
              onClick={() => handleStatusChange("Rejected")}
              sx={{
                height: "3vw",
                width: "12vw",
                marginLeft: "5vw",
                fontSize: "1vw",
              }}
            >
              Reject
            </Button>
          </>
        )}
        {courseStatus === "Not Approved" && (
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "#8E00FD", color: "white" }}
              onClick={() => handleStatusChange("Approved")}
              sx={{
                height: "3vw",
                width: "12vw",
                marginLeft: "5vw",
                marginTop: "1vw",
                fontSize: "1vw",
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "#C5C5C5", color: "white" }}
              onClick={() => handleStatusChange("Rejected")}
              sx={{
                height: "3vw",
                width: "12vw",
                marginLeft: "5vw",
                fontSize: "1vw",
              }}
            >
              Reject
            </Button>
          </>
        )}
        {courseStatus === "Rejected" && (
          <Button
            variant="contained"
            style={{ backgroundColor: "#8E00FD", color: "white" }}
            onClick={() => handleStatusChange("Approved")}
            sx={{
              height: "3vw",
              width: "12vw",
              marginLeft: "5vw",
              marginTop: "1vw",
              fontSize: "1vw",
            }}
          >
            Approve
          </Button>
        )}
      </Box>
    </div>
  );
};

export default CourseActionApprovalComponent;
