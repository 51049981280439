import "../../styling/training/CourseLandingPage.css";
import Button from "@mui/material/Button";

import axiosInstance from "../../utils/axiosInstance";
import { getHeaders } from "../utils/useAuth";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";

const CourseActionEnrollComponent = ({
  CourseID,
  setShowThumbnail,
  getCourseDetails,
  setStartCourse,
  setEnrollCourse,
}) => {
  const navigate = useNavigate();
  const [enrollStatus, setEnrollStatus] = useState("");

  // const [courseId, setCourseId] = useState(courseID);
  const [courseTitle, setCourseTitle] = useState();
  const [courseBanner, setCourseBanner] = useState();
  // const [courseStatus, setCourseStatus] = useState();

  const fetchCourseEnrollmentStatus = () => {
    axiosInstance
      .get(`/training/`)
      .then((res) => {
        const course = res.data.find((course) => course.Course_id === CourseID);
        console.log("course id recieved in course enroll component", CourseID);
        console.log(course);

        if (course) {
          // If course exists, set the enrollment status from the course object
          setEnrollStatus(course.Course_status);
        } else {
          // If course does not exist, set enrollment status to "not_enrolled"
          setEnrollStatus("NotEnrolled");
        }
      })
      .catch((err) => {
        console.log("Error fetching training data", err);
      });
  };
  const fetchCourseDetails = () => {
    axiosInstance
      .get(`/courses/` + CourseID)
      .then((res) => {
        console.log("course details fetched");
        console.log(res.data);
        setCourseTitle(res.data["title"]);
        setCourseBanner(res.data["course_banner"]);
      })
      .catch((err) => {
        console.log("Error fetching user data", err);
      });
  };

  useEffect(() => {
    fetchCourseEnrollmentStatus();
    fetchCourseDetails();
  }, [CourseID]);

  const handleEnrollClick = (courseID) => {
    console.log("Enroll button clicked");
    // Add your enroll logic here
    var payload = {
      course_id: courseID,
    };
    console.log("============================");
    console.log("header value");
    console.log(getHeaders());
    console.log("payload value");
    console.log(payload);
    console.log("============================");
    axiosInstance
      .post(`/enroll/assign`, payload)
      .then((res) => {
        console.log("Course enrollment response");
        console.log("response recieved");
        console.log(res.data);
        // navigate(`/my-courses`, { state: { tab: 2 } });
        fetchCourseEnrollmentStatus();
        // setShowThumbnail(false);
      })
      .catch((err) => {
        console.log("error enrolling into course", err);
      });
  };

  const handleGetStartedClick = (
    courseID,
    courseTitle,
    courseBanner,
    courseStatus
  ) => {
    console.log("Get Started button clicked");
    // Add your get started logic here
    // passing the courseID and courseTitle to the thumbnail
    setShowThumbnail(true);
    getCourseDetails(courseID, courseTitle, courseBanner);
    // already course is assigned
    if (
      courseStatus === "Complete" ||
      courseStatus === "In Progress" ||
      courseStatus === "Pending"
    ) {
      setStartCourse(true);
      setEnrollCourse(false);
    } else {
      setStartCourse(false);
      setEnrollCourse(true);
    }
  };

  const handleResumeCourseClick = () => {
    console.log("Resume Course button clicked");
    // Add your resume course logic here
  };

  const handleViewCertificate = (courseID) => {
    // navigate("/view-certificate", { state: { courseID } });
    navigate("/my-certificates", { state: { courseID } });
  };

  let button;

  if (enrollStatus === "NotEnrolled") {
    button = (
      <div>
        <p>Enroll into the course</p>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleEnrollClick(CourseID)}
          sx={{ height: "3vw", width: "12vw", fontSize: "1vw" }}
        >
          Enroll
        </Button>
      </div>
    );
  } else if (enrollStatus === "Pending") {
    button = (
      <div>
        <p>Get started with the course</p>
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            handleGetStartedClick(
              CourseID,
              courseTitle,
              courseBanner,
              enrollStatus
            )
          }
          sx={{ height: "3vw", width: "12vw", fontSize: "1vw" }}
        >
          Get Started
        </Button>
      </div>
    );
  } else if (enrollStatus === "In Progress") {
    button = (
      <div>
        <p>Resume the course!</p>
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            handleGetStartedClick(
              CourseID,
              courseTitle,
              courseBanner,
              enrollStatus
            )
          }
          sx={{ height: "3vw", width: "12vw", fontSize: "1vw" }}
        >
          Resume Course
        </Button>
      </div>
    );
  } else if (enrollStatus === "Complete") {
    button = (
      <Box display="flex" flexDirection="column" gap={2}>
        <div
          style={{ marginLeft: "0vw", marginTop: "1vw", fontWeight: "bold" }}
        >
          <span style={{ display: "block" }}>Congratulations!</span>
          <span style={{ display: "block" }}>
            You've successfully completed the course !
          </span>
        </div>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleViewCertificate(CourseID)}
          sx={{
            height: "3vw",
            width: "12vw",
            marginLeft: "5vw",
            marginTop: "1vw",
          }}
        >
          View Certificate
        </Button>
      </Box>
    );
  }

  return <div className="tab-div-bg">{button}</div>;
};

export default CourseActionEnrollComponent;
