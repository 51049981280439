import React, { useState, useEffect } from "react";
import "../styling/UserProfile.css";
import Navbar from "../components/Navbar";
import DP from "../images/man.png";
import { Link } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import { getHeaders } from "../components/utils/useAuth";
import AlertMessage from "../utils/alertMessage";
import ConfirmationPopup from "../utils/confirmationPopup";

export default function UserProfile() {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    variant: "success",
  });

  const [confirmState, setConfirmState] = useState({
    open: false,
    message: "",
    onConfirm: () => {},
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  const [name, setName] = useState("John Doe");
  const [phone, setPhone] = useState("1234567890");
  const [email, setEmail] = useState("john.doe10@xyx.com");
  const [role, setRole] = useState("Manager");
  const [designation, setDesignation] = useState("Software Developer");
  const [profilePic, setProfilePic] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [userID, setUserID] = useState();
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePic(reader.result);
        setIsDirty(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNameChange = (e) => {
    const inputName = e.target.value;
    // const namePattern = /^[A-Za-z]+(?: [A-Za-z]+)*$/; // Regex pattern to allow only words separated by spaces

    // if (!namePattern.test(inputName)) {
    //   setNameError("Only alphabets and spaces are allowed.");
    // } else {
    //   setNameError("");
    // }

    setName(inputName);
    setIsDirty(true);
  };

  const handlePhoneChange = (e) => {
    const inputPhone = e.target.value;

    // Allow only numbers and limit to 10 digits
    if (/^\d{0,10}$/.test(inputPhone)) {
      setPhone(inputPhone);
      setPhoneError("");
    } else if (inputPhone.length > 10) {
      setPhoneError("Phone number cannot exceed 10 digits.");
    } else {
      setPhoneError("Only numeric values are allowed.");
    }

    setIsDirty(true);
  };

  const saveData = () => {
    const [firstName, lastName] = name.split(" ");
    const payload = {
      first_name: firstName || "",
      last_name: lastName || "",
      email: email,
      phone: phone.toString(),
      profile_picture: profilePic,
    };

    const handleConfirm = () => {
      axiosInstance
        .put(`/users/${userID}`, payload)
        .then((res) => {
          console.log("User profile updated successfully:", res.data);
          setAlertState({
            open: true,
            message: "Successfully updated profile info.",
            variant: "success",
          });
        })
        .catch((error) => {
          console.error("Error while updating user profile:", error);
          setAlertState({
            open: true,
            message: "Error updating profile info.",
            variant: "error",
          });
        });
    };

    setConfirmState({
      open: true,
      message: "Are you sure you want to update the info?",
      onConfirm: handleConfirm,
    });
  };

  const cancelChanges = () => {
    const handleConfirm = () => {
      window.location.reload();
    };

    setConfirmState({
      open: true,
      message: "Changes will be reset, you want to proceed?",
      onConfirm: handleConfirm,
    });
  };

  useEffect(() => {
    axiosInstance
      .get(`/users/user_details`)
      .then((res) => {
        setName(`${res.data.first_name} ${res.data.last_name}`);
        setUserID(res.data.id);
        setEmail(res.data.email);
        setRole(res.data.role);
        setDesignation(res.data.designation);
        setProfilePic(res.data.profile_image || DP);
        setPhone(res.data.phone);
      })
      .catch((err) => {
        console.error("Error while fetching user details:", err);
      });
  }, []);

  return (
    <>
      <Navbar />
      <div className="profile-bg">
        <div className="upper-section">
          <p>User Settings</p>
          <div className="upper-section-buttons">
            <button className="save" onClick={saveData}>
              Save
            </button>
            <button className="cancel" onClick={cancelChanges}>
              Cancel
            </button>
          </div>
        </div>

        <div className="up-lower-section">
          <p>Personal Information</p>
          <div className="personal-details">
            <div className="left-column">
              <img className="dp" src={profilePic} alt="Profile" />
              <input type="file" onChange={handleProfilePicChange} />
            </div>
            <div className="middle-column">
              <div>
                <label>Name</label>
                <input type="text" value={name} onChange={handleNameChange} />
                {nameError && <p className="error-text">{nameError}</p>}
              </div>
              <div>
                <label>Phone Number</label>
                <input
                  type="text"
                  value={phone}
                  onChange={handlePhoneChange}
                  maxLength="10"
                />
                {phoneError && <p className="error-text">{phoneError}</p>}
              </div>
            </div>
            <div className="right-column">
              <div>
                <label>Email-ID</label>
                <input type="email" value={email} disabled />
              </div>
              <div>
                <label>Designation</label>
                <input type="text" value={designation} disabled />
              </div>
            </div>
          </div>
          <div className="fgt-pwd">
            Change Password?
            <Link to="/reset-password">
              <i>Reset Password</i>
            </Link>
          </div>
        </div>
        {/* Confirmation Popup */}
        {confirmState.open && (
          <ConfirmationPopup
            message={confirmState.message}
            onConfirm={() => {
              confirmState.onConfirm();
              setConfirmState({ ...confirmState, open: false });
            }}
            onCancel={() => setConfirmState({ ...confirmState, open: false })}
            onClose={() => setConfirmState({ ...confirmState, open: false })}
          />
        )}

        {/* Single AlertMessage Component */}
        {alertState.open && (
          <AlertMessage
            message={alertState.message}
            variant={alertState.variant}
            onClose={() => setAlertState({ ...alertState, open: false })}
          />
        )}
      </div>
    </>
  );
}
