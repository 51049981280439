import { useEffect, useState, useRef } from "react";
import "../styling/Lesson.css";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router-dom";
import { LessonData } from "../LessonData";
import SubmitModal from "../components/SubmitModal";
import axiosInstance from "../utils/axiosInstance";
import { getHeaders } from "../components/utils/useAuth";
import AlertMessage from "../utils/alertMessage";
import AdminLeftSideBar from "../components/admin/common/AdLeftSideBar";
import AdminNavbar from "../components/admin/common/AdNavbar";

export default function Lesson() {
  const location = useLocation();
  const data = location.state;
  // const data = [{"moduleTitle": " Introduction to Data Protection"},...LessonData];
  console.log(location.state.setCompletedModules);
  const [showModal, setShowModal] = useState(false);
  const [LessonNum, setLessonNum] = useState(1);
  const [markedAns, setMarkedAns] = useState({});
  const [totalQues, setTotalQues] = useState(0);
  const [attemptedQuestions, setAttemptedQuestions] = useState(0);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    variant: "success",
  });
  const myDivRef = useRef(null);

  const previousButtonHandler = (LessonNumber) => {
    if (LessonNumber > 1) setLessonNum(LessonNumber - 1);
    console.log(
      "total ques of lesson ",
      LessonNumber,
      "==> ",
      totalQues - data[LessonNumber]["Questions"].length
    );
    setTotalQues(totalQues - data[LessonNumber]["Questions"].length);
    if (myDivRef.current) {
      myDivRef.current.scrollTop = 0;
    }
  };

  const nextButtonHandler = (LessonNumber) => {
    if (attemptedQuestions < totalQues) {
      setAlertState({
        open: true,
        message: "Attempted questions not equal to total questions.",
        variant: "error",
      });
      return;
    } else {
      if (myDivRef.current) {
        myDivRef.current.scrollTop = 0;
      }
    }

    if (LessonNumber < data.length - 1) {
      setLessonNum(LessonNumber + 1);
      console.log(
        "total ques of lesson ",
        LessonNumber,
        "==> ",
        totalQues + data[LessonNumber + 1]["Questions"].length
      );
      setTotalQues(totalQues + data[LessonNumber + 1]["Questions"].length);
    } else {
      setShowModal(true);
    }
  };
  const changeMarkedAns = (Q_ID, Q_opt) => {
    // console.log("Ques ID: ", Q_ID,"  markedAns: ", Q_opt);
    // console.log("new ans: ", {[JSON.stringify(Q_ID)]:String.fromCharCode(Q_opt+65)});
    console.log("new ans onject: ", {
      ...markedAns,
      [JSON.stringify(Q_ID)]: String.fromCharCode(Q_opt + 65),
    });
    setMarkedAns({ ...markedAns, [Q_ID]: String.fromCharCode(Q_opt + 65) });
  };

  //count the total questions on every lesson page load
  useEffect(() => {
    console.log(
      "total ques of lesson ",
      LessonNum,
      "==> ",
      totalQues + data[LessonNum]["Questions"].length
    );
    setTotalQues(totalQues + data[LessonNum]["Questions"].length);
    if (data[0]["moduleStatus"] != "Complete") {
      axiosInstance
        .get(
          `/training/update_course_progress?course_id=${data[0]["courseID"]}&module_id=${data[0]["moduleID"]}`
        )
        .then((res) => {
          console.log("module status updated", res.data);
        })
        .catch((err) => {
          console.log(
            "Sorry, there was an error while updating the module status",
            err
          );
        });
    }
  }, []);
  useEffect(() => {
    // console.log("number of attempted ques are: ",Object.keys(markedAns).length);
    setAttemptedQuestions(Object.keys(markedAns).length);
  }, [markedAns]);

  return (
    <div className="lesson-container-bg">
      <AdminLeftSideBar />

      {showModal ? (
        <SubmitModal
          courseDetails={{
            courseID: data[0]["courseID"],
            moduleID: data[0]["moduleID"],
            moduleTitle: data[0]["moduleTitle"],
            totalModules: data[0]["totalModules"],
          }}
          markedAns={markedAns}
          totalQues={totalQues}
          attemptedQues={attemptedQuestions}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      ) : null}
      <div
        style={{ filter: showModal ? "blur(20px)" : "", position: "relative" }}
      >
        {/* <Navbar /> */}
        {/* <AdminNavbar /> */}
        <AdminNavbar />
        <div className={`lesson-bg ${showModal}?'darken':''}`}>
          {/* <h1>{data[0]["moduleTitle"]}</h1> */}
          <h1 dangerouslySetInnerHTML={{ __html: data[0]["moduleTitle"] }}></h1>

          <div>
            <div id="course-content">
              {/* <p>{data[LessonNum]["Lesson_title"]}</p> */}
              <p
                dangerouslySetInnerHTML={{
                  __html: data[LessonNum]["Lesson_title"],
                }}
              ></p>
              <div id="sub-topics">
                <p
                  dangerouslySetInnerHTML={{
                    __html: data[LessonNum]["Lesson_description"],
                  }}
                ></p>
              </div>
            </div>

            <div id="course-questions" ref={myDivRef}>
              {data[LessonNum]["Questions"].map((question, index) => {
                console.log("Current question object:", question);
                console.log("options are: ", question["Options"]);

                // const options = JSON.parse(`${question["Options"]}`);
                //    const options = JSON.parse(question["Options"]);
                const options = question["Options"];
                return (
                  <>
                    {/* <p className="question">
                      {index + 1}. {question["Question"]}
                    </p> */}
                    <p
                      className="question"
                      dangerouslySetInnerHTML={{
                        __html: `${index + 1}. ${question["Question"]}`,
                      }}
                    ></p>
                    {options.map((option, opt_index) => {
                      return (
                        <div style={{ marginLeft: "3vw", paddingTop: "1vw" }}>
                          <input
                            type="radio"
                            value={option}
                            checked={
                              markedAns[question["Question_id"]]?.charCodeAt(
                                0
                              ) %
                                65 ==
                              opt_index
                            }
                            id={`${question["Question_id"]}-${opt_index}`}
                            name={question["Question_id"]}
                            onChange={() =>
                              changeMarkedAns(
                                question["Question_id"],
                                opt_index
                              )
                            }
                          />
                          {/* <label
                            for={`${question["Question_id"]}-${opt_index}`}
                            className="option"
                          >
                            {option}
                          </label> */}
                          <label
                            htmlFor={`${question["Question_id"]}-${opt_index}`}
                            className="option"
                            dangerouslySetInnerHTML={{ __html: option }} // Changed to use dangerouslySetInnerHTML
                          ></label>
                          <br />
                        </div>
                      );
                    })}
                  </>
                );
              })}

              <button
                className={`previousLessonButton ${
                  LessonNum == 1 ? "disabled" : ""
                }`}
                onClick={() => previousButtonHandler(LessonNum)}
                disabled={LessonNum == 1 ? true : false}
              >
                Previous
              </button>

              <button
                className="nextLessonButton"
                // disabled={attemptedQuestions == totalQues ? false : true}
                onClick={() => nextButtonHandler(LessonNum)}
              >
                {LessonNum === data.length - 1 ? "Submit" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {alertState.open && (
        <AlertMessage
          message={alertState.message}
          variant={alertState.variant}
          onClose={() => setAlertState({ ...alertState, open: false })}
        />
      )}
    </div>
  );
}
